/**
 * @file
 * Bem block for node.
 */
.node__title {
  .title {
    margin-top: 0;
  }
}

.node__date {
  @extend .date;
}

.node-page,
body:not(.node-type-news) .view-mode-full {
  // @extend .has-padding-space-base;
}