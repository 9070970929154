.listing--guides {
  margin-top: 60px;

  @include respond-to(mobile-and-down) {
    margin-top: 20px;
  }

  .views-field-field-more {
    color: $text-color;
    font-weight: $bold;
  }
}

.guide-links {
  font-size: 23px;
  margin-bottom: 20px;

  a:first-child {
    font-weight: $bold;
  }

  a {
    white-space: nowrap;
  }
}

.views--listing--layout .view-accommodation-guide {
  margin-top: 60px;
  @extend .group-narrow;

  @include respond-to(mobile-and-down) {
    margin-top: 20px;
  }

  .field-name-title {
    color: $link-color;
  }

  .views-row {
    padding-top: 15px;
  }
}