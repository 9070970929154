.view-bfm-shows {
  margin-top: $spacing-base;
  
  @include respond-to(tablet-and-down){
    display: none;
  }

  table, .table {
    border-collapse: inherit;
    font-size: 14px;
    
    @include respond-to(desktop-and-down){
      font-size: 12px;  
    }

    img {
      max-width: 100%;
    }

    &.table-striped>tbody>tr:nth-child(odd)>td,
    &.table-striped>tbody>tr:nth-child(odd)>th,
    &.table-striped > tbody > tr:nth-of-type(odd) {
      background-color: transparent;
    }

    &.table-striped > tbody > tr > td.td-now.td-today {
      background: #f9f9f9;
    }

    thead tr th {
      border: 0;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      text-transform: uppercase;
      width: 12.5%;
    }
    tbody td {
      border: 0;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      position: relative;
    }

    .show-flyout {
      display: none;
      background: $white;
      bottom: 100%;
      left: 50%;
      padding: 15px;
      position: absolute;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 300px;
      z-index: 999;
      box-shadow: 0 5px 20px rgba(0,0,0,0.2);

      &:after {
        border-style: solid;
        border-width: 15px 35px 0 35px;
        border-color: #ffffff transparent transparent transparent;
        bottom: -15px;
        content: "";
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 0;
      }

      picture {
        display: block;
        margin: -15px -15px 10px;
      }
    }

    tbody td:nth-child(2) {
      .show-flyout {
        left: 0;
        transform: none;

        &:after {
          left: 20px;
          transform: none;
        }
      }
    }

    tbody td:last-child {
      .show-flyout {
        left: inherit;
        right: 0;
        transform: none;

        &:after {
          left: inherit;
          right: 30px;
          transform: none;
        }
      }
    }
  }

  .td-time {
    width: 8%;
  }

  .th-time {
    text-indent: -999em;
  }

  .timefield-minical-label strong {
    font-weight: normal !important;
  }
}

table.sticky-header {
  background: #eeeeee;
  padding-top: 115px;
  left: 50% !important;
  transform: translatex(-50%);
  z-index: 600;

  th {
    padding: 8px;
  }
}

// Mobile display
.block-shows-mobile {
  margin: 5px 0 0;
  display: none;

  @include respond-to(tablet-and-down){
    display: block;
    margin-left: -$content-gutter-width/3;
    margin-right: -$content-gutter-width/3;
  }

  @include respond-to(tablet-large-and-down){
    margin-left: -$content-gutter-width/6;
    margin-right: -$content-gutter-width/6;
  }
  
  @include respond-to(mobile-and-down){
    margin-left: -20px;
    margin-right: -20px;
  }
  
  h3 {
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    margin: 0;
    padding: 10px 20px 15px;
    text-align: center;
    text-transform: uppercase;
  }

  .bx-wrapper .bx-controls-direction a {
    top: 10px;

    &.bx-next {
      right: 20px;
    }
    &.bx-prev {
      left: 20px;
    }
    
    @media (forced-colors: active) {
      text-indent: 0;
    }
  }

  .show {
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
  }
}

.user-profile {

  > h3 {
    display: none;
  }
  
  .field-name-field-image {
    margin: $line-height-computed 0;
  }

  .field-name-field-show-timeslot {
    color: $secondary-text;
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center;
  }

  .field-group-div {
    @extend .clearfix;
  }

  .field-name-field-twitter-link,
  .field-name-field-facebook-link,
  .field-name-field-instagram-link {
    @extend .clearfix;
    float: left;
    margin: 20px 10px 30px 0;

    a {
      color: #07a8e9;
      display: block;
      font-weight: $bold;
      text-indent: -9999em;
      width: 37px;

      &:before {
        border: 2px solid #07a8e9;
        border-radius: 100%;
        color: #07a8e9;
        content: "\e607";
        display: inline-block;
        float: left;
        font-family: 'ignite-icons';
        text-shadow: none;
        height: 37px;
        margin-right: 10px;
        padding: 5px;
        position: relative;
        text-align: center;
        text-indent: 0;
        vertical-align: middle;
        width: 37px;
      }

      &:hover,
      &:hover:before {
        border-color: lighten(#07a8e9, 6%);
        color: lighten(#07a8e9, 6%);
      }
    }
  }

  .field-name-field-facebook-link {

    a {
      color: #3a5998;

      &:before {
        border-color: #3a5998;
        color: #3a5998;
        content: "\e603";
      }

      &:hover,
      &:hover:before {
        border-color: lighten(#3a5998, 6%);
        color: lighten(#3a5998, 6%);
      }
    }
  }

  .field-name-field-instagram-link {
    margin-bottom: 60px;

    a {
      color: #458eff;

      &:before {
        background: url(../images/bfm-icon_ig--blue.svg) no-repeat center center;
        border-color: #458eff;
        color: #458eff;
        content: "";
      }

      &:hover,
      &:hover:before {
        border-color: lighten(#458eff, 6%);
        color: lighten(#458eff, 6%);
      }
    }
  }

  .node-sponsor {
    background: $white;
    display: inline-block;
    vertical-align: middle;

    img {
      height: 100px;
      margin: 15px;
      width: auto;
    }
  }

}

.sponsors__inner {
  background: $white;
  margin-top: 30px;
  padding: 30px 0;
  text-align: center;
  @extend .clearfix;

  @include respond-to(mobile-and-down){
    text-align: center;
  }

  img {
    height: 100px;
    width: auto;
    image-rendering: crisp-edges;
  }
}