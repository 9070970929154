@import "../support";

// This mixin provides basic support for CSS3 properties and
// their corresponding experimental CSS2 properties when
// the implementations are identical except for the property
// prefix.
@mixin experimental($property, $value,
  $moz      : $experimental-support-for-mozilla,
  $webkit   : $experimental-support-for-webkit,
  $o        : $experimental-support-for-opera,
  $ms       : $experimental-support-for-microsoft,
  $khtml    : $experimental-support-for-khtml,
  $official : true
) {
  @if $webkit  and $experimental-support-for-webkit    { -webkit-#{$property} : $value; }
  @if $khtml   and $experimental-support-for-khtml     {  -khtml-#{$property} : $value; }
  @if $moz     and $experimental-support-for-mozilla   {    -moz-#{$property} : $value; }
  @if $ms      and $experimental-support-for-microsoft {     -ms-#{$property} : $value; }
  @if $o       and $experimental-support-for-opera     {      -o-#{$property} : $value; }
  @if $official                                        {         #{$property} : $value; }
}

// Same as experimental(), but for cases when the property is the same and the value is vendorized
@mixin experimental-value($property, $value,
  $moz      : $experimental-support-for-mozilla,
  $webkit   : $experimental-support-for-webkit,
  $o        : $experimental-support-for-opera,
  $ms       : $experimental-support-for-microsoft,
  $khtml    : $experimental-support-for-khtml,
  $official : true
) {
  @if $webkit  and $experimental-support-for-webkit    { #{$property} : -webkit-#{$value}; }
  @if $khtml   and $experimental-support-for-khtml     { #{$property} :  -khtml-#{$value}; }
  @if $moz     and $experimental-support-for-mozilla   { #{$property} :    -moz-#{$value}; }
  @if $ms      and $experimental-support-for-microsoft { #{$property} :     -ms-#{$value}; }
  @if $o       and $experimental-support-for-opera     { #{$property} :      -o-#{$value}; }
  @if $official                                        { #{$property} :         #{$value}; }
}
