.block-bfm-top10 {
  margin-bottom: $spacing-base;
  @extend .group-narrow;

  form {
    .form-item-email {
      float: right;
      width: 45%;
      @include respond-to(mobile-and-down){
        float: none;
        width: 100%;
      }
    }
    .form-item-name {
      float: left;
      width: 45%;
      @include respond-to(mobile-and-down){
        float: none;
        width: 100%;
      }
    }
    .form-item-top-10-vote-selected {
      clear: both;
      margin-bottom: 30px;
    }
  }

  .vote-form--text {
    margin-bottom: 20px;
  }
}

.block-bfm-top10 {
  form:before {
  }

  form {
    .form-item-top-10-vote-ac {
      clear: both;
    }
  }
}

.file-bcast-file {
  margin-top: 30px;
  @extend .group-narrow;
}

.node-top-10 {
  .submitted {
    display: none;
  }
}

.top10__text-instructions {
  margin-bottom: $spacing-base*3;
  padding: 0 $spacing-base*3;
  @include respond-to(tablet-large-and-down){
    padding: 0 $spacing-base;
  }

  @include respond-to(mobile-and-down){
    margin-bottom: 60px;
    padding: 0 20px;
  }
}


.top10-listing-block, .view-top10 {

  .block-title {
    font-weight: normal;
    margin-top: 0;
  }

  .table, table {
    border: 0;
    border-collapse: inherit;
    margin-bottom: 10px;
    > thead > tr > th {
      border-bottom: 1px solid #ddd;
      color: $tertiary-text; 
      font-size: 14px;
      text-transform: uppercase;

      &:first-child {
        border-left: 0;
      }

      @include respond-to(mobile-and-down){
        &:nth-child(2),
        &:last-child {
          display: none;
        }
      }
    }

    > tbody > tr > td {
      border: 0;
      border-bottom: 1px solid #ddd;
      font-size: 17px;
      font-weight: $bold;
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        border-left: 0;
      }

      @include respond-to(mobile-and-down){
        &:nth-child(2),
        &:last-child {
          display: none;
        }
      }
    }

    .views-field-field-top10-total-count {
      @include respond-to(mobile-and-down){
        display: none;
      }
    }
  }

  .block-title {
    color: $secondary-text;
    font-size: 24px;
    margin-bottom: $spacing-half;
    text-align: center;

    @include respond-to(mobile-and-down){
      font-size: 16px;
      margin-bottom: 30px;
    }
  }

  .views-field-counter {
    color: $link-color;
  }

  .view-footer {
    text-align: center;

    a {
      background: transparent;
      border-radius: 0;
      border: 0;
      color: $link-color;
      float: left;
      font-size: 14px;
      font-weight: normal;
      margin-top: 0;
      padding: 0;
      text-transform: none;
    }
  }

  .view-top10-items {
    @extend .group-narrow;
  }

}


.top10-listing-block {
  .view-top10-items {
    tbody > tr > td, thead > tr > th {
      padding: 15px 10px;
    }

    td.views-field-title {
      font-weight: normal;
    }
  }
}

.view-top10 {
  @extend .group-narrow;
}