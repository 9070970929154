iframe {
  max-width: 100%;
}

html {
}

body {
  background: #333;
}

#main-content-wrapper {
  background: #eeeeee;
}

.pre-content {

}

.site-wrapper {
  padding-top: 119px;

  @include respond-to(mobile-and-down){
    padding-top: 108px;
  }
}

.block-main-title {
  text-align: center;
  #page-title {
    display: inline-block;
  }
}

.group-narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 840px;
}

.site__content.has-sidebar {
  
  @extend .border-box;
  @include respond-to(tablet-and-down) {
    float: left;
    padding-right: 0;
    width: 60%;  
  }
  @include respond-to(tablet-large-and-down) {
    float: none;
    padding-right: 20px;
    width: 100%;
  }

  .block-title {
    color: $secondary-text;
    font-size: 22px;
    font-weight: $bold;
    margin-bottom: 20px;
    margin-top: 0;
  }
}

.sidebar {
  float: right;
  width: 30%;
}

@include respond-to(tablet-large-and-down){
  .has-sidebar,
  .sidebar {
    float: none;
    width: 100%;
  }
}
