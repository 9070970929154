/**
 * @file
 * BEM block for pre-content.
 */
.pre-content {
  //margin: $spacing-base 0 0;
  margin: 0;
  @include respond-to(desktop-and-up) {
    margin: 0;
  }
}

.pre-footer {
  @extend .remove-margin-bottom-double;
}