/**
 * @file
 * BEM block for site.
 */
.site__inner {
  @extend .border-box;
  @extend .container;
  @include respond-to(wide-large) {
    max-width: 1440px;
  }
}

.site__row {
  @extend .row;
}

.site-wrapper__row {
  position: static;
}

.site__content {
  @include make-md-column(12);
  // margin-top: $spacing-base;
  margin-bottom: $spacing-base;
  padding-left: 20px;
  padding-right: 20px;
  
  &.content--nomargin {
    margin-bottom: 0;
    margin-top: 0;
  }
  &.has-sidebar {
    @include make-md-column(8);
    @include make-lg-column(9);
    padding-left: 20px;
    padding-right: 20px;

    @include respond-to(tablet-large-and-up) {
      padding-left: $content-gutter-width/3;
      padding-right: $content-gutter-width/3;
    }
  
    @include respond-to(desktop-and-up) {
      padding: 0 0 0 $content-gutter-width/2;
      width: 66%;
      > div {
        padding-right: em(100);
      }
    }
    @include respond-to(wide-and-up) {
      padding: 0 0 0 $content-gutter-width;
      width: 75%;
      > div {
        padding-right: em(120);
      }
    }
  }

  @include respond-to(tablet-and-up) {
    padding-left: $content-gutter-width/6;
    padding-right: $content-gutter-width/6;
  }

  @include respond-to(tablet-large-and-up) {
    padding-left: $content-gutter-width/3;
    padding-right: $content-gutter-width/3;
  }

  @include respond-to(desktop-and-up) {
    padding-left: $content-gutter-width/2;
    padding-right: $content-gutter-width/2;
  }

  @include respond-to(wide-and-up) {
    padding-left: $content-gutter-width;
    padding-right: $content-gutter-width;
  }
}

.site__sidebar {
  @include make-md-column(4);
  @include make-lg-column(3);
  padding-left: 0;
  padding-right: 0;
  @include respond-to(tablet-and-up) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include respond-to(tablet-large-and-up) {
    padding-left: $content-gutter-width/3;
    padding-right: $content-gutter-width/3;
    float: right;
    padding-left: 0;
    width: 40%;
  }

  @include respond-to(desktop-and-up) {
    padding-left: 0;
    padding-right: $content-gutter-width/2;
    width: 34%;
  }

  @include respond-to(wide-and-up) {
    padding-right: $content-gutter-width;
    width: 25%;
  }
}

.site__sidebar-inner {
  .block {
    padding-bottom: $spacing-double;
    @include respond-to(tablet-large-and-down) {
      padding-bottom: 0;
    }
  }
  @include respond-to(desktop-and-up) {
    padding: 0;
  }
  @include respond-to(desktop-and-down) {
    padding-left: $content-gutter-width/3;
  }
  @include respond-to(tablet-large-and-down) {
    padding-left: 0;
  }
}
