.file-bcast-file {
  background: $white;
  margin-bottom: 10px;
  min-height: 148px;
  padding: 25px 245px 25px 25px;
  position: relative;

  @include respond-to(tablet-and-down){
    padding-right: 23px;
  }

  audio {
    /*display: none;*/
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  h3 {
    color: $primary-text;
    margin: 0;
  }

  a h3 {
    color: $link-color;
    font-size: 20px;
    font-weight: $bold;
    margin: 0;

    &:hover {
      color: $primary-text;
    }

    @include respond-to(mobile-and-down){
      font-size: 16px;
    }
  }

  img {
    max-width: 222px;

    @include respond-to(mobile-and-down){
      width: 100%;
    }
  }

  .file {
    color: $tertiary-text;
    font-size: 15px;
    text-transform: capitalize;

    @include respond-to(mobile-and-down){
      font-size: 14px;
    }

    a, img {
      display: none;
    }

    .file--file-type {
      text-transform: uppercase;
    }
  }

  .field-date {
    color: $tertiary-text;
    display: inline;
    font-size: 15px;
    &:before {
      content: ",";
      display: inline-block;
      margin-right: 5px;
    }

    @include respond-to(mobile-and-down){
      font-size: 14px;
    }
  }

  .field-name-download-file a {
    background: transparent url(../images/bfm-icon_download.svg) no-repeat center center;
    border-top: 1px solid #edebed;
  }

  .field-name-field-term-bcast,
  .field-name-field-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 200;

    @include respond-to(tablet-and-down){
      float: none;
      margin-top: 10px;
      position: static; 
    }
  }

  .group-playlist {
    margin-top: 3px;
    .field-group-format-toggler {
      font-size: 16px;
      font-weight: normal;
    }
    .field-group-format-title {
      text-transform: lowercase;
      vertical-align: top;

      &:focus {
        text-decoration: none;
      }

      &:after {
        content: "Hide the";
        float: left;
        margin-right: 5px;
        display: inline-block;
        text-transform: none;
      }

      &:before {
        content: "Show the";
        display: none;
        float: left;
        margin-right: 5px;
        text-transform: none;
      }

    }
    &.collapsed {
      .field-group-format-title {
        &:before {
          display: inline-block;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  .toggle-audio .play,
  .toggle-audio .pause,
  .field-name-download-file a {
    display: block;
    height: 74px;
    text-indent: -9999em;
    width: 68px;
  }

  .toggle-audio {
    width: 69px;

    a {
      top: 0;
      position: absolute;
    }

    .pause {
      background: transparent url(../images/bfm-icon_pause-02.svg) no-repeat center center;
      display: none;
    }

    .play {
      background: transparent url(../images/bfm-icon_play-03.svg) no-repeat center center;
    }
  }

  .toggle-audio,
  .field-name-download-file {
    border-right: 1px solid #edebed;
    left: 0;
    position: absolute;
    z-index: 200;
  }

  .toggle-audio {
    height: 100%;
    top: 0;
  }

  .field-name-download-file {
    bottom: 0;
    top: 70px;
  }

}

.view-bcasts {
  .file-bcast-file {
    max-width: none;
  }
}

.view-bcast-term  {

  a {
    color: $primary-text;
    
    &:hover {
      color: $link-color;
    }
  }

  .bcast--links a,
  .bcast--links button {
    background: transparent;
    color: $link-color;
    font-size: 23px;
    font-weight: normal;
    padding: 0;
    vertical-align: bottom;

    &:hover {
      color: $primary-text;
    }

    
  }

  .bcast--links button:focus,
  .bcast--links button:active {
    box-shadow: none;
    outline: 0;
  }

  .bcast--links {
    .dropdown {
      display: inline-block;
      vertical-align: bottom;

      button {
        padding-right: 20px;
        position: relative;

        &:after {
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: $link-color transparent transparent transparent;
          content: "";
          display: block;
          height: 0;
          position: absolute;
          right: 3px;
          top: 15px;
          width: 0;
        } 

        &:hover:after {
          border-color: $primary-text transparent transparent transparent;
        }
      }
    }
  }

  .dropdown-menu {
    padding: 10px;
    a {
      color: $primary-text;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;

      &:hover {
        color: $link-color;
      }
    }
  }

  img {
    height: auto !important;
    max-width: 100%;
  }

  ul li {
    list-style: none;
  }

  ul {
    padding-left: 0;

    li > ul {
      padding-left: 20px;
    }
  }

  .field-name-bcast-links {
    margin-bottom: 25px;
  }
}

.taxonomy-term {
  text-align: center;

  .field-name-bcast-show-details {
    font-weight: normal;
    margin-top: 0;
    @extend .h3;

    a {
      color: $link-color;
    }

    a:before {
    }
  }

  .field-name-bcast-subscription-links {
    a {
      color: $link-color;
      // margin: 0 10px 0 0;
    }
  }
}

.page-bcasts {
  .hero-banner {
    margin-top: 30px;
  }
}
