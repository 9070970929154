/**
 * @file
 * Override Bootstrap button styles
 */
.btn-primary {
  &:visited {
    color: $white;
  }
}

.btn-info {
  background: $button-color;
  border: 0;
  font-weight: $bold;
  text-transform: uppercase;

  &:hover {
    background: $button-color;
  }
}

.btn {
  background: $button-color;
  border: 0;
  color: $white;
  font-weight: $bold;
  &:hover, &:active, &:focus {
    background: darken($button-color, 6%);
    color: $white;
  }
}

.btn:disabled {
  background: #2f2f2f;
  color: $white;
}