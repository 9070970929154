.node-event-form {
  margin-top: 40px;
  padding-top: 40px;
  @extend .has-border-top;

  > div {
    @extend .group-narrow;
  }

  table {
    td {
      padding: 0 !important;
    }

    tr, td, .panel-default>.panel-heading {
      background: transparent !important;
    }

    a.tabledrag-handle {
      height: 45px;
      margin-left: 0;
    }

    a.tabledrag-handle .handle {
      background-position: 6px -14px;
      margin: 9px 0;
      padding: 0.12em 0.5em;
    }
  }

  .btn {
    margin: 30px 0;
    text-transform: none;
  }

  .checkbox label {
    padding-left: 0;
  }

  .date-float {
    margin-bottom: 10px;
  }

  .date-no-float {
  }

  .form-type-date-popup {
    >label {
      display: block;
    }
  }

  .panel {
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    .panel-body {
      padding: 0;
    }
    .panel-heading {
      padding-left: 0;
      font-weight: $bold; 
    }
  }
}