.search-result {
  img {
    max-width: 222px;
    width: auto;
  }

  .field-name-field-teaser-image,
  .field-name-field-page-banner {
    @include respond-to(mobile-and-down){
      display: none;
    }
  }

  .field-show-details,
  .field-bcast-type,
  .field-name-content-type {
    color: $tertiary-text;
    font-weight: $bold;
  }
}

.view-multi-search {
  //@extend .has-padding-space-base;
  margin-top: 60px;

  a {
    color: $text-color;
  }

  h2 {
    margin-top: 0;

    &:hover {
      color: $link-color;
    }
  }

  .field-name-field-page-banner,
  .field-name-field-teaser-image {
    position: absolute;
    right: 0;
    top: 0;
    @include respond-to(mobile-and-down){
      margin-bottom: 20px;
      position: static;
    }
  }

  .field-name-show-name-with-user {
    margin-top: 0;
  }

  .file-bcast-file {
    background: transparent;
    padding: 30px 252px 30px 0;

    .field-name-field-name {
      @extend .h2;
      margin-top: 0;

      &:hover {
        color: $link-color;
      }
    }
  }

  .search-results__description {
    float: left;
  }

  > .view-content > .views-row > div {
    border-top: 1px solid #ddd;
    min-height: 252px;
    padding: 30px 252px 30px 0;
    position: relative;
    @include respond-to(mobile-and-down){
      min-height: 0;
      padding-right: 0;
    }

    &.node-page {
      padding-left: 0;
      padding-right: 0;
    }
  }
}