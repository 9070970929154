/**
 * @file
 * Optimized typographic spacing in roughly 700px wide setting.
 *
 * (Classic node body area - Add .prose via display suite as required)
 * http://www.pearsonified.com/typography/
 */
.prose {
  @include respond-to(tablet-and-up) {
    line-height: $line-height-base-prose;
    ul,
    ol,
    dt,
    dd {
      line-height: $line-height-base-prose;
    }
    ul,
    ol {
      margin-bottom: ($line-height-prose-computed/2);
      ul,
      ol {
        margin-bottom: 0;
      }
    }
    dl {
      margin-bottom: $line-height-prose-computed;
      &.dl-horizontal {
        dt {
          margin-top: 0;
        }
      }
    }
    blockquote {
      padding: ($line-height-prose-computed / 2) $line-height-prose-computed;
      margin: 0 0 $line-height-prose-computed;
      line-height: $line-height-base;
      &.featured {
        padding: $line-height-prose-computed $line-height-prose-computed;
      }
    }
    address {
      margin-bottom: $line-height-prose-computed;
    }
    h1, .h1,
    h2, .h2,
    h3, .h3 {
      margin-top: ($line-height-prose-computed*2);
      margin-bottom: ($line-height-prose-computed/2);
    }
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      margin-top: $line-height-prose-computed;
      margin-bottom: ($line-height-prose-computed/2);
    }
    p {
      margin: 0 0 ($line-height-prose-computed/2);
    }
  }
  .lead {
    margin-bottom: $line-height-prose-computed;
    font-size: floor(($font-size-base * 1.15));
    @include respond-to(tablet-and-up) {
      font-size: ($font-size-base * 1.5);
    }
  }
}
