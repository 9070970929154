/**
 * @file
 * BEM block for content.
 */

 
.content {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
  -o-animation-delay: 2s; /* Safari 4.0 - 8.0 */
  // padding-top: $spacing-base;
  .front & {
    padding-top: 0;
  }
  @include respond-to(desktop-and-up) {
    // padding-top: $spacing-and-half;
    .front & {
      padding-top: 0;
    }
  }
}
.taxonomy-term-description {
  margin: 20px 20px 0;
}