/**
 * @file
 * BEM block for gallery style lists
 */

/**
 * t2-d3
 *
 * 1. Naming convention for modifiers. For example:
 *    .responsive-layout--t2-d3 translates to layout of 2 columns for
 *    tablet breakpoint ('t' for tablet) and 3 columns for desktop and up
 *    ('d' for desktop).
 *
 * 2. We add and additional class (responsive-layout--item) because
 *    views converts the correct class (responsive-layout__slat).
 *    See https://www.drupal.org/node/1371118
 */
.responsive-layout--t2-d3 { /* [1] */
   @include container(content-box);
  .responsive-layout--item, /* [2] */
  .responsive-layout__item {
    margin-bottom: $spacing-base;
    width: 100%;
    @include respond-to(tablet) {
      &:nth-child(odd) {
        @include span(3 of 6 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(3 of 6 before last);
      }
    }
    @include respond-to(desktop-and-up) {
      @include span(4 of 12 before);
      &:nth-child(3n+1) {
        @include span(4 of 12 before first);
        clear: both;
      }
      &:nth-child(3n+3) {
        @include span(4 of 12 before last);
      }
    }
  }

  &.responsive-layout--nomargin {
    .responsive-layout--item, /* [2] */
    .responsive-layout__item {
      margin: 0;

      @include respond-to(tablet) {
        width: 50%;
        &:nth-child(odd) {
          clear: both;
        }
      }
      @include respond-to(desktop-and-up) {
        width: 33.33%;
        &:nth-child(3n+1) {
          clear: both;
        }
      }
    }
  }
}

/* d2 */
.responsive-layout--d2 {
  .responsive-layout--item,
  .responsive-layout__item {
    @include respond-to(tablet-large-and-up) {
      &:nth-child(odd) {
        @include span(4 of 8 before first);
        clear: both;
      }
      &:nth-child(even) {
        @include span(4 of 8 before last);
      }
    }
  }

  &.reverse-layout {
    .responsive-layout--item,
    .responsive-layout__item {
      @include respond-to(tablet-large-and-up) {
        &:nth-child(odd) {
          @include span(4 of 8 before last);
          float: right;
          clear: both;
        }
        &:nth-child(even) {
          @include span(4 of 8 before first);
        }
      }
    }
  }
}
