/**
 * @file
 * Global typography
 */
body {
  background: $secondary-bg;
  font-size: $font-size-base;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased; -moz-font-smoothing: antialiased; -o-font-smoothing: antialiased;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2;font-weight: $bold; }
h3, .h3 { font-size: $font-size-h3;font-weight: $bold; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed/2);
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($line-height-computed/2);
  margin-bottom: ($line-height-computed/2);
}

h1, .h1 {
  font-weight: $bold;
  text-align: center;
  @include respond-to(mobile) {
    font-size: $font-size-h3;
    margin-top: 0;
  }
  @include respond-to(tablet) {
    font-size: $font-size-h2;
  }
}

h2, .h2 {
  @include respond-to(mobile) {
    font-size: $font-size-h4;
  }
  @include respond-to(tablet) {
    font-size: $font-size-h3;
  }
}

h3, .h3 {
  color: $secondary-text;
  @include respond-to(mobile) {
    font-size: $font-size-h4;
  }
  @include respond-to(tablet) {
    font-size: $font-size-h4;
  }
}
h4, .h4 {
  color: $secondary-text;
  font-size: 24px;

  @include respond-to(mobile) {
    font-size: 16px;
  }
}

.page-user-reset #page-title {
  background: transparent;
}

.block-main-title {
  margin-left: 20px;
    margin-right: 20px;
}

// TODO (tg) Temp - Delete later
.special__title {
  background-color: rgba(255,255,0,0.3);
  border-bottom: 1px solid $brand-border;
  padding: ($line-height-computed);
}

p {
  margin: 0 0 ($line-height-computed);
  &:last-child {
    margin: 0;
  }
}

hr {
  border-top: 1px solid $brand-border;
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.15));
  @include respond-to(tablet-and-up) {
    font-size: ($font-size-base * 1.5);
  }
}

.has-bulleted-list {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 25px;
      text-indent: -10px;
      &:before {
        content: "• ";
        color: $link-color;
        font-size: 18px;
        display: inline-block;
        text-indent: -4px;
      }
    }
  }
}


blockquote {
  border-left: 10px solid $brand-gray-light;
  &.featured {
    color: $white;
    background-color: $gray-dark;
    background-position: right 0;
    background-repeat: no-repeat;
    border-left: none;
    @include respond-to(wide-and-up) {
      width: 400px;
    }
    a {
      color: $white;
    }
  }
}

.blockquote-reverse {
  border-right: 10px solid $brand-gray-light;
}

.alert-info, .messages, .webform-confirmation {
  background: #30708e;
  border: 0;
  color: $white;
  margin-bottom: -1px;
  padding: 15px;
  @extend .has-margin-base;
}

.links a {
  @extend .btn;
  margin-top: 20px;
}

.links {
  @extend .has-margin-base;
}

.messages {
  margin-bottom: 20px;
  margin-top: 20px;
  @extend .group-narrow;
}

.message.error {
  padding: 15px;
  @extend .group-narrow;
}

a {
  color: $link-color;
  .field-name-body & {
    text-decoration: underline;
  }
  &:visited {
    color: $link-color;
  }
  &.btn:visited {
    color: white;
  }
  &:hover {
    color: $primary-text;
    text-decoration: none;
  }
}

/* Legal style nested ordered lists */
ol {
  ol {
    list-style-type: lower-alpha;
    ol {
      list-style-type: lower-roman;
    }
  }
}
