.pager {
  a {
    color: #777;
    font-size: 20px;
    font-weight: $bold;
    text-transform: uppercase;
  }

  .pager-next a {
    background: $white;
    border-radius: 0;
    border: 0;
    padding: 20px 30px;

    &:hover {
      background: $primary-bg;
      color: $white;
    }
  }
}

.view-footer {
  text-align: center;
}

.view-footer a {
  background: $white;
  color: $secondary-text;
  display: inline-block;
  font-size: 18px;
  font-weight: $bold;
  padding: 20px 40px;
  text-transform: uppercase;
}