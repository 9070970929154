/**
 * @file
 * Override the CSS from the social_share module
 */
.social-share {
  text-align: center;

  &:before {
    content: "Share this";
    font-size: 14px;
    font-weight: $bold;
    margin: 0 auto;
    text-transform: uppercase;
  }
  > div {
    > a {
      display: inline-block;
      font: 0/0 a;
      text-shadow: none;
      border: 2px solid #3a5998;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      @extend .icon--pseudo;
      @extend .icon--absolute;
      width: 40px;
      height: 40px;
      position: relative;
      color: #3a5998;
      padding: 5px;
      margin: 15px 2px 1px 2px;
      &:before {
        top: -2px;
        left: 9px;
        font-size: 20px;
        line-height: 40px;
      }
      &:hover,
      &:focus {
        opacity: 0.8;
        text-decoration: none;
        &:before {
        }
      }
      
      &.social-share-twitter {
        border-color: #07a8e9;
        color: #07a8e9;
      }

      &.social-share-linkedin {
        border-color: #ef6552;
        color: #ef6552;
      }
    }
  }
}

.social-share-facebook {@extend .icon--facebook;}
.social-share-twitter {@extend .icon--twitter;}
.social-share-linkedin {@extend .icon--linkedin;}
.social-share-email {@extend .icon--envelope;}
