/**
 * @file
 * BEM block for footer.
 */
 .footer {
  font-size: 14px;
  background: $primary-bg url(../images/bfm-bg-footer.gif) center center;
  color: $tertiary-text;
  padding-top: $spacing-base;
  padding-bottom: $spacing-base;
  position: relative;
  z-index: 700;
  @extend .has-margin-top-double;
  @include respond-to(desktop-and-up) {
    padding-top: $spacing-double;
    padding-bottom: $spacing-double;
  }
  a {
    color: $gray;
    text-decoration: underline;
  }

  .icon--bfmlogo {
    background: url(../images/logo-bfm-footer.svg) no-repeat center center;
    height: 122px;
    margin: 0 auto;
    width: 82px;
  }

  .site__inner {
    padding: 0 20px;
  }

  .site__row {
    margin-left: 0;
    margin-right: 0;
  }
}

.call-to-action {

  > div {
    display: block;
    float: left;
    vertical-align: middle;
    width: 50%;

    @include respond-to(tablet-and-down){
      display: block;
      float: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    h3 {
      margin-top: 0;
    }

    &:not(.contextual-links-processed) a {
      background: #2f2f2f;
      border-radius: 3px;
      color: $white;
      display: inline-block;
      font: 700 22px/22px "acumin-pro-condensed";
      margin-top: 8px;
      padding: 5px 20px 10px;
      text-decoration: none;

      &:hover {
        background-color: lighten(#2f2f2f, 6%);
      }

      @include respond-to(tablet-and-down){
        margin-bottom: 30px;
      }
    }
  }
}

.media {
  margin: 0;
}

.region-footer {
  text-align: center;
  
  div.block,
  // .call-to-action,
  .social-share {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    vertical-align: middle;
    width: 22.5%;

    @include respond-to(mobile-and-down){
      width: 100%;
    }
  }

  div.footer-logo {
    padding: 0;
    text-align: center;
    width: 10%;

    @include respond-to(tablet-and-down){
      display: none;
    }
    
    p {
      margin: 0;
    }
  }

  div.call-to-action {
    margin-top: 10px;
    width: 45%;

    @include respond-to(mobile-and-down){
      float: none;
      width: 100%;

      &.right {
        // margin-top: 20px; 
        margin-top: 0;
      }
    }

    h3 {
      font: 700 23px/25px "acumin-pro-condensed";
    }
  }

  .block-title {
    font: 700 23px/25px "acumin-pro-condensed";
    margin-bottom: 20px;
    margin-top: 0;
  }

  .social-media {
    // padding: 10px 20px 0;
    padding: 0;

    @include respond-to(tablet-and-down){
      margin: 0 0 30px;
    }

    h3 {
      margin-bottom: 20px;
    }

    p a {
      background: #302e30 url(../images/bfm-icon_fb.svg) no-repeat center center; 
      border-radius: 3px;
      display: inline-block;
      height: 38px;
      margin: 0 2px;
      text-indent: -9999em;
      width: 38px;

      &.ig {
        background: #302e30 url(../images/bfm-icon_ig.svg) no-repeat center center; 
      }

      &.tw {
        background: #302e30 url(../images/bfm-icon_tw.svg) no-repeat center center; 
      }

      &.yt {
        background: #302e30 url(../images/bfm-icon_yt.svg) no-repeat center center; 
      }
      
      &:hover {
        background-color: lighten(#302e30, 6%);
      }
    }

    > div {
      float: none;
      margin: 0 auto;
    }

    div.block {
      text-align: center;
      width: auto;
    }
  }
}