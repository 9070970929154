.container, .site__inner, #mmenu_top .mm-list {
  width: auto !important;
}

.site__inner {
  @include respond-to(mobile-and-down){
    padding-left: 20px;
    padding-right: 20px;
  }
}

.site__row {
  @include respond-to(mobile-and-down){
    margin-left: -20px;
    margin-right: -20px;
  }
}
