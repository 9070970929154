/**
 * @file
 * Override Bootstrap breadcrumb styles
 */
.breadcrumb {
  background: transparent;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
  > .breadcrumb__item {
    + li {
      &:before {
        color: $text-color;
        padding: 0 7px 0 10px;
      }
    }
  }

  li {
    font-weight: $bold;

    a, a:visited {
      color: $text-color;
      font-weight: $bold;
    }
  }
}

.block-crumbs {
  border-top: 1px solid #ccc;
  margin: 0 $content-gutter-width -20px;
  padding-top: 40px;

  @include respond-to(desktop-and-down){
    margin: 0 $content-gutter-width/2 -20px;
    padding-top: 10px;
  }

  @include respond-to(tablet-large-and-down) {
    margin: 0 20px 5px;
  }
}

.breadcrumb__item:last-child {
  display: none;
}