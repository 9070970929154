/**
 * @file
 * Custom tweaks to admin elements
 */
.alert {
  margin-top: $spacing-base;
  .close {
    line-height: 0.8em;
  }
}

.tabs--primary {
  margin: $spacing-base 0;
}

.contextual-links {
  a {
    background-color: transparent !important;
  }
}

.nav-pills {
  > li {
    > a {
      color: #333;
      text-decoration: underline;
      text-decoration-color: currentColor;
      text-underline-offset: 5px;
    }
    &.active {
      > a {
        background: none !important;
        color: $link-color !important;
        font-weight: 700;
      }
    }
    &:hover {
      > a {
        color: $link-color;
        background: none;
      }
    }
  }
}