/**
 * @file
 * BEM block for controls.
 */
.control--menu {
  @include make-xs-column(2);
  float: right;
  padding: 0;
  z-index: 400;
  @include respond-to(desktop-and-up) {
    // display: none;
  }

  @include respond-to(mobile-and-down){
  }
}