.loader { 
  background: transparent;
  display: none;
  position: absolute;
  left: 0px;
  top: 200%;
  width: 100%;
  height: 100%;
  z-index: 9999; 
}

.spinner {
  margin: 100px auto;
  width: 30px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #ccc;
  float: left;
  height: 100%;
  width: 3px;
  margin: 0 1px;
  display: block;
  border-radius:6px;
  
  -moz-animation: stretchdelay 1.1s infinite ease-in-out;
  -webkit-animation: stretchdelay 1.1s infinite ease-in-out;
  animation: stretchdelay 1.1s infinite ease-in-out;
}

.spinner .rect2 {
  background-color: #ccc;
  -moz-animation-delay: -1.0s;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  border-radius:6px;
}

.spinner .rect3 {
  background-color: #ccc;
  -moz-animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  border-radius:6px;
}

.spinner .rect4 {
  background-color: #ccc;
  -moz-animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  border-radius:6px;
}

.spinner .rect5 {
  background-color: #ccc;
  -moz-animation-delay: -0.7s;
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
  border-radius:6px;
}

.spinner .rect6 {
  background-color: #ccc;
  -moz-animation-delay: -0.6s;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
  border-radius:6px;
}

@-moz-keyframes stretchdelay {
  0%, 40%, 100% { -moz-transform: scaleY(0.4) }  
  20% { -moz-transform: scaleY(1.0) }
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}