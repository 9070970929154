/**
 * @file
 * BEM block for logo.
 */
.logo__link {
  display: block;
  line-height: 0;
}

.logo--primary {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 400;

  .logo__link {
    &:hover,
    &:focus {
      .logo__brand {
        fill: darken(#CCCCCC,10%);
      }
    }
  }
  .logo__brand {
    fill: #CCCCCC;
    @include single-transition(all, 0.05s, ease-in);
  }
}

.logo--secondary {
  .logo__brand {
    fill: $gray;
  }
  .logo__link {
    width: 191px;
    height: 22px;
    &:hover,
    &:focus {
      .logo__brand {
        fill: $gray-dark;
      }
    }
  }
}
