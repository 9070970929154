.field-name-field-song-list,
.block-playlist .view-id-set_lists .views-row,
.group-box {
  background: $white;
  margin: 20px 0;
  padding: 25px 30px;
}

.view-id-set_lists .views-field-title,
.node-set-list h2,
.group-box h2 {
  color: $link-color;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.views-field-field-song-list {
  margin-top: 10px;

  .node-teaser {
    margin-bottom: 5px;
  }
}

.field-name-field-song-list {
  .node-teaser {
    margin-bottom: 5px;
  }
}

.view-set-lists,
.node-set-list,
.group-box {
  .field-name-field-song-list {
    padding: 0;
  }
}

.page-set-lists {
  .view-id-set_lists .views-field-title {
    border-bottom: 1px solid #ddd;
    font-size: 17px;
    padding: 4px 0 7px;
  }
  .field-name-field-song-list {
    // padding: 25px 30px;
  }
}

.node-set-list {
  .field-name-body,
  .user-profile {
    margin-top: 20px;
  }
}

.field-name-field-artist {
  font-weight: bold;
}

.set-list--links {
  background: transparent;
  color: $link-color;
  font-size: 23px;
  font-weight: normal;
  padding: 0;
  text-align: center; 
  vertical-align: bottom;
}

.page-set-lists .node-set-list {
  background-color: $white;
  margin-bottom: 25px;
  padding: 25px 30px;
}

.page-set-lists .node-set-list .field-name-body {
}