/**
 * @file
 * BEM block for promoted content.
 */
.promotion--copyright {
  @include make-xs-column(12);
  @include make-sm-column(9);
  @include make-lg-column(10);
  padding-top: $spacing-three-quarters;
  padding-bottom: $spacing-three-quarters;
  @include respond-to(tablet-and-up) {
    padding-top: $spacing-half;
    padding-bottom: $spacing-half;
  }
}

.promotion--brand {
  // @include make-xs-column(10);
  // @include make-md-column(3);
  // position: static;
}

.promotion--associated-organisation  {
  @include make-xs-column(12);
  @include make-md-column(3);
  @include make-lg-column(2);
  @include make-lg-column-offset(1);
  padding-top: $spacing-base;
  padding-bottom: $spacing-base;
  @include respond-to(tablet-and-up) {
    padding-top: $spacing-half;
    padding-bottom: $spacing-half;
  }
}

.promotion--social-share {
  line-height: 0;
}

.promotion--in-this-section {
  border-top: 1px solid $brand-border;
  > .block-title {
    font-size: $font-size-h4;
  }
}
