.staggered-features {
  margin-top: 30px;
  padding-bottom: 50%;
  position: relative;
  @extend .clearfix;

  @include respond-to(tablet-and-down) {
    padding-bottom: 0;
  }

  @include respond-to(tablet-and-up) {
    > div:not(.view-footer) {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
    }
  }

  @include respond-to(desktop-and-up) {
    > div:not(.view-footer) {
      -moz-column-count: inherit;
      -webkit-column-count: inherit;
      column-count: inherit;
    }
  }

  .view-footer {
    display: none;
    text-align: center;

    @include respond-to(mobile-and-down){
      display: block;
    }
  }
}

.staggered-features__item, .staggered-features--item  { // Thanks views! 
  display: block;
  float: left;
  overflow: visible;
  position: relative;
  -webkit-column-break-inside: avoid; /* Chrome, Safari */
  page-break-inside: avoid;           /* Theoretically FF 20+ */
  break-inside: avoid-column;
  // max-width: 510px;

  a:focus, a:hover {
    color: $text-color;

    h4 {
      text-decoration: underline;
    }
  }

  a:not(.contextual-links-trigger) {
    background-color: $primary-bg;
    img {
      height: auto;
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.4s ease;
    }

    &:hover  img {
      opacity: 0.8;
      transition: opacity 0.4s ease;
    }

    .field-name-field-image {
      background-color: $primary-bg;
    }
  }

  @include respond-to(desktop-and-up) {
    margin-bottom: 0;
    width: 33.3%;

    &:nth-child(3n) {
      float: right;
      transform: translate(- + random(40) + %, 0);
      z-index: random(400);

      > div {
        float: right;
      }
    }

    &:nth-child(3n-1) {
      transform: translate(- + random(10) + %, - + random(60) + %);
      z-index: random(300);
    }

    &:nth-child(3n-2) {
      clear: left;
      transform: translate(0, - + (random(10) + 10) + %);
      z-index: random(300);
    }

    &:nth-child(even),
    &:nth-child(2) {
      z-index: 300;
    }

    &:first-child {
      transform: translate(0, 33%);
      z-index: 400;
    }

    &:nth-child(2) {
      transform: translateY(0);
    }

    &:nth-child(3) {
      transform: translate(0, 50%);
      z-index: 500;
    }

    &:nth-child(4) {
      // transform: translate(0, 428px);
      // z-index: random(300);
      transform: translate(0,250px);
      z-index: 300;
    }

    &:nth-child(5) {
      transform: translate(2%, 92%);
      z-index: 500;
    }

    &:nth-child(6) {
      transform: translate(0, 117%);
    }

    &:nth-child(7) {
      // transform: translate(0, 70%);
      transform: translate(0, 117%);
    }

    &:nth-child(8) {
      // transform: translate(-10%, -26%);
      transform: translate(-10%,70%);
    }
    &:nth-child(9) {
      // transform: translate(-12%, 42%);
      transform: translate(-12%,82%);
    }

    &:hover {
      z-index: 600;

      > div {
        box-shadow: 0 0 20px rgba(0,0,0,0.2);
      }
    }

    > div {
      width: 400px;//510px;

      @include respond-to(desktop-and-down){
        width: 300px;
      }
    }

  }

  @include respond-to(tablet-and-down) {
    margin-bottom: 20px;
    position: static;
    width: 100%;
  }

  > div {
    background-color: $white;
    text-align: left;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

  img {
    width: 100%;
    height: auto;
  }

  .field-name-field-image {
    margin: 0;
  }

  .field-name-title {
    padding: 30px 20px;

    h4 {
      color: $text-color;
      font-size: 24px;
      font-weight: $bold;
      margin: 0;
    }
  }
  
}
