
#mmenu_top {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  > .mm-list > .mmenu-expanded {
    display: none;
  }

  @include respond-to(wide-and-down) {
    > .mm-list {
      width: 100%;  
    }
  }
  
  .form-group {
    margin: 15px auto 5px auto;
    width: 300px;

    .input-group-btn {

      .btn {
        background: transparent url(../images/icon_search.svg) no-repeat center center;
        background-size: 24px 26px;
        display: inline-block;
        margin: 3px 10px;
        padding: 18px 20px;
        cursor: pointer;
        border-radius: 6px;
        vertical-align: middle;
        @include transition(opacity,0.2s,ease);
        
        .glyphicon-search {
          display: none;
        }  
      }
      
    }

    @include respond-to(desktop-and-up) {
      width: 840px;

      .input-group {
        width: 100%;

        input {
          height: 42px;
          line-height: 42px;
          @include border-radius(4px);  
        }
      }
    }
  }
  
  // position: fixed;
  top: 0;
  width: 100%;
  max-width: none;
  margin: 0px auto;
  height: 57px;
  min-height: 0px;
  background: transparent;
  transition: transform 0.25s ease-in-out;

  > ul {
    padding: 0;
  }

  .logged-in & {
    top: 29px;

    @include respond-to(tablet-large-and-down) {
    }
  }
  
  .mmenu-top-in & {
    top: 0px;
  }

  .mmenu-top-in.logged-in & {
    top: 29px;

    @include respond-to(tablet-large-and-down) {
      top: 0px;
    }

  }

  .mm-right & {
    position: static;
  }
 
  .mm-list {
    padding: 0;
    position: static;
    height: auto;
    background-color: #eeeeee;

    > li > span {
      padding: 0;
    }
    
    @extend .container;
  
    @include respond-to(tablet-large) {
      width: auto; /*Recommended to be 20px less than lg breakpoint. */
    }

    @include respond-to(desktop) {
      width: auto; /*Recommended to be 20px less than lg breakpoint. */
      margin: 0;
    }

    @include respond-to(wide-large) {
      width: 100%; /* Recommended to be 20px less than xlg breakpoint. */
    }
  }

  .form-group {
    margin: 0;
  }

  .views-exposed-widgets {
    padding: 10px 0;
    text-align: center;
  }

  .views-exposed-form .views-exposed-widget {
    display: inline-block;
    float: none;
    padding: 0;
    vertical-align: middle;

    .form-submit {
      margin-top: 0;
    }
  }

  .views-exposed-form {
    margin: 0;
    padding: 0;
  }
}

body {
  padding-top: 0px;
  transition: padding-top 0.25s ease-in-out, margin-top 0.25s ease-in-out;
  
  &.mmenu-top-in {
    //padding-top: 57px;
  }
}