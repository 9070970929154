.region-hero,
.region-post-content {
  opacity: 0;

  animation: fadein 2s;
  animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
  animation-fill-mode: forwards;

  -moz-animation: fadein 2s; /* Firefox */
  -moz-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
  -moz-animation-fill-mode: forwards;

  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
  -webkit-animation-fill-mode: forwards;

  -o-animation: fadein 2s; /* Opera */
  -o-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
  -o-animation-fill-mode: forwards;
}

.post-content {
  .site__row {
    margin-left: 0;
    margin-right: 0;
    @include respond-to(desktop-and-down){
      margin-left: -$content-gutter-width/2;
      margin-right: -$content-gutter-width/2;
    }
    @include respond-to(tablet-and-down){
      margin-left: -53.33333px;
      margin-right: -53.33333px;
    }

    @include respond-to(tablet-large-and-down){
      margin-left: -60px;
      margin-right: -60px;
    }

    @include respond-to(mobile-and-down){
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.region-content {
  position: relative;
  z-index: 400;
}