/**
 * @file
 * BEM block for navigation
 */
.navigation--primary {
  @include respond-to(tablet-and-down) {
    .post-header & {
      display: none;
    }
  }
  .menu {
    .menu {
/*      display: none;*/
      z-index: -1;
      display: block;
      @include opacity(0);
      position: absolute;
      min-width: 200px;
      .menu__item {
        @include respond-to(desktop-and-up) {
          float: none;
        }
        a {
          width: 100%;
        }
      }
    }
  }
  .menu__item {
    @include respond-to(desktop-and-up) {
      // float: left;
    }
    .post-header & {
      /* On hover display the sub menu */
      &:hover {
        > .menu {
          @include respond-to(desktop-and-up) {
            @include opacity(1);
            z-index: 499;
          }
        }
      }
    }
  }
  .menu__link {
    font-size: 16px;
    color: $white;
    @include font-smoothing;
    &:hover,
    &:focus,
    &.is-active,
    &.is-active-trail {
      color: $white;
    }
  }
}

.nav {
  margin-top: 50px;
}

.navigation--secondary {
  > .block-title {
    font-size: $font-size-h4;
    @extend .has-no-margin-top;
  }
  .menu {
    .menu {
      .menu__link {
        border-bottom: none;
        padding: $spacing-quarter 0 $spacing-quarter $spacing-half;
      }
      .menu {
        .menu__link {
          padding: $spacing-quarter 0 $spacing-quarter $spacing-base;
        }
        .menu {
          .menu__link {
            padding: $spacing-quarter 0 $spacing-quarter $spacing-and-half;
          }
        }
      }
    }
  }
  .menu__item {
    float: none;
  }
  .menu__link {
    background-color: transparent;
    padding: $spacing-quarter 0;
    &:hover,
    &.active {
      background-color: transparent;
      color: $text-color;
    }
    &.active {
      font-weight: bold;
    }
  }
}

.navigation--tertiary {
  padding-top: 6px;
  @include make-xs-column(12);
  @include make-sm-column(3);
  @include make-md-column(5);
  @include make-md-column-offset(1);
  @include make-lg-column(4);
  @include make-lg-column-offset(2);
  .menu {
    padding: 0;
    @include respond-to(tablet) {
      padding: 0 0 $spacing-base;
    }
  }
  .menu__item {
    @include respond-to(tablet-and-up) {
      float: right;
    }
  }
  .menu__link {
    padding: $spacing-quarter 0;
    @include respond-to(tablet-and-up) {
      padding: em(3);
      margin: 0 $spacing-base 0 0;
    }
    &:hover,
    &:focus {
      color: $brand-primary;
    }
    &.active {
      text-decoration: underline;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.navigation--quaternary {
  @include make-xs-column(12);
  @include make-md-column(9);
  .menu {
    padding: $spacing-half 0 0;
    @include respond-to(tablet) {
      padding: 0 0 $spacing-base;
    }
  }
  .menu__item {
    @include respond-to(tablet-and-up) {
      float: left;
    }
  }
  .menu__link {
    color: $gray;
    padding: $spacing-quarter 0;
    @include respond-to(tablet-and-up) {
      padding: em(3);
      margin: 0 $spacing-base 0 0;
    }
    &:hover,
    &:focus,
    &.active {
      color: $gray-darker;
    }
    &.active {
      text-decoration: underline;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.navigation--site-map {
  .menu {
    > .menu__item {
      > .menu__link {
        font-size: $font-size-h3;
        margin-top: $spacing-base;
        border-bottom: 1px solid $gray-light;
        padding: $spacing-half $spacing-half $spacing-half 0;
        @include respond-to(tablet-and-up) {
          margin-top: $spacing-double;
        }
        &:hover,
        &:focus {
          background-color: transparent;
          text-decoration: underline;
        }
      }
      > .menu {
        > .menu__item {
          > .menu__link {
            font-size: em(14);
            border-bottom: none;
            margin-top: $spacing-base;
          }
          > .menu {
            border-top: 1px solid $brand-border;
            > .menu__item.is-expanded {
              @include respond-to(tablet-and-up) {
                @include span(2 of 6 after no-gutters);
                &:nth-child(3n+1) {
                  clear: both;
                }
              }
              @include respond-to(desktop-and-up) {
                @include span(2 of 12 after no-gutters);
                &:nth-child(3n+1) {
                  clear: none;
                }
                &:nth-child(6n+1) {
                  clear: both;
                }
              }
              .menu__link {
                margin-top: 0;
                color: lighten($gray-dark, 20%);
              }
              > .menu {
                border-top: none;
                .menu__link {
                  font-size: em(14);
                  margin-left: $spacing-half;
                  border-left: 1px solid $brand-border;
                  padding: $spacing-quarter $spacing-half;
                }
              }
            }
          }
        }
      }
    }
  }
  .menu__link {
    color: $gray-dark;
  }
}
