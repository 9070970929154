.pre-ajax {
  background: $primary-bg;
  color: $tertiary-text;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 20px;
  text-align: center;

  &:hover {
    color: $white;

    a {
      color: $white;
    }
    .play {
      opacity: 1;
    }

    .pause {
      opacity: 1;
    }
  }

  @include respond-to(mobile-and-down){
    text-align: left;
  }

  a {
    color: $tertiary-text;
  }

  @include respond-to(desktop-and-up){
    // left: 0;
    // position: fixed;
    // right: 0;
    // z-index: 400;
  }

  .block-bfm-stream {
    position: absolute;
    visibility: hidden;
  }

  .play, .pause {
    display: inline-block;
    height: 29px;
    font-size: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    opacity: 0.5;
    width: 30px;

    &:hover {
      opacity: 1;
    }
  }

  .pause {
    text-indent: -9999em;
    display: none;

    &:after {
      content: "";
      background: transparent url(../images/bfm-icon_audio.svg) no-repeat center bottom;
      display: block;
      height: 29px;
      width: 30px;
    }

    &:before {
      content: "";
      background: transparent url(../images/bfm-icon_pause.svg) no-repeat center center;
      display: none;
      height: 29px;
      width: 30px;
    }

    &.mouseOut:hover:before {
      display: block;
    }

    &.mouseOut:hover:after {
      display: none;
    }
  }

  .play {
    background: transparent url(../images/bfm-icon_play.svg) no-repeat center center;
    height: 29px;
    opacity: 0.5;
    text-indent: -9999em;
  }
}


.pre-ajax {
  .block-bfm-tweaks,
  .block-bfm-playlist,
  .block-bfm-controller {
    display: inline-block;
    vertical-align: middle;
  }
  .block-bfm-tweaks,
  .block-bfm-playlist {
    width: 40%;
    text-align: right;

    @include respond-to(mobile-and-down){
      width: 73%;
    }
    
    p {
      display: inline;
    }

    .field-content {
      height: 20px;
      white-space: nowrap;
    }
    .views-field {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
  }

  .block-bfm-tweaks {

    @include respond-to(mobile-and-down){
      display: none;
    }

    .views-field {
      // -webkit-animation: leftIndent 5s infinite; /* Chrome, Safari, Opera */
      // animation: leftIndent 5s infinite;
    }
  }

  .block-bfm-playlist {
    text-align: left;

    .views-field {
      // -webkit-animation: rightIndent 5s infinite; /* Chrome, Safari, Opera */
      // animation: rightIndent 5s infinite;
    }
  }

  .block-bfm-tweaks {
    .views-field-field-show-timeslot {
      display: none;
    }
  }
}


  