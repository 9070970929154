.view-header {

  h3 {
    // color: $secondary-text;
    // font-size: 23px;
  }

  h3 a {
    font-weight: normal;
  }
}

.views-footer-controller {
  margin-bottom: 30px;
  text-align: center;

  a:not(.contextual-links-trigger) {
    background: #ffffff;
    color: #999;
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 30px;
  }
}

.views--listing--layout {
  .item-list,
  .views-row {
    background: $white;
    margin-bottom: 10px;
    padding: 0 20px 10px;
  }

  .views-field-contextual-links {
    display: inline;
  }

  .views-field-field-event-ticket-summary {
    text-indent: 0;
  }
}

.view-commerce-card-on-file-user-cards {
  table {
    > thead > tr {
      th {
        padding-bottom: 15px;
        font-size: 18px;
        font-weight: 700;
        border-color: #999;
        border-width: 1px;
      }
    }
    > tbody > tr {
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        border-color: #999;
      } 
    }
    .commerce-cardonfile-edit, .commerce-cardonfile-delete {
      padding-right: 0;
      a {
        margin-top: 0;
        background: white;
        border: 1px solid $link-color;
        color: $link-color;
        text-transform: capitalize;
        border-radius: 2px;
        
      }
    }
    .views-field-card-exp {
      .card-expired {
        color: #333;
      }
    }
    tr.is-default-Yes {
      font-weight: 700;
    }
  }
  
}