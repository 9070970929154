.listing-block--sponsorship {
  font-size: 18px;
  text-align: center;
  @extend .has-margin-top-base;
  @extend .has-white-bg;
  padding-bottom: 50px;
  padding-top: 50px;

  ul {
    list-style: none;
    padding: 0;
    > li {
      display: inline-block;
      padding: 15px;
      @extend .border-box;
      @include respond-to(tablet-large-and-down){
        display: block;
        float: left;
        width: 50%;

        img {
          height: auto !important;
          max-width: 100%;
        }
      }
    }
  }

  .sponsors {
    margin-top: 50px;
  }
}

.listing-block--sponsorship-friends {
  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    li {
      flex: 1 0 auto;
      float: none;
      width: auto;
      padding: 0;
      @include respond-to(tablet-large-and-down) {
        padding: 15px 0;
      }
    }
  }
}