/**
 * @file
 * Ignites custom icon font for social icons and UI features.
 *
 * See ../../fonts/ignite-icons/README.md for details
 */

@font-face {
  font-family: 'ignite-icons';
  src:url('../fonts/ignite-icons/ignite-icons.eot');
  src:url('../fonts/ignite-icons/ignite-icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ignite-icons/ignite-icons.woff') format('woff'),
    url('../fonts/ignite-icons/ignite-icons.ttf') format('truetype'),
    url('../fonts/ignite-icons/ignite-icons.svg#ignite-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon {
  font-family: 'ignite-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--inline {
  @extend %icon;
}

.icon--pseudo {
  &:before {
    @extend %icon;
  }
}

.icon--absolute {
  position: relative;
  &:before {
    position: absolute;
  }
}

.bfm-icon {
  &.search {
    background: transparent url(../images/bfm-icon_search.svg) no-repeat center center;
    display: block;
    height: 30px;
    margin: 7px 0;
    width: 30px;
  }
}

.icon--menu:before {
  background: transparent url(../images/bfm-icon_menu.svg) no-repeat center center;
  content: "";
  display: block;
  height: 22px;
  width: 24px;
}

.icon--close:before {
  background: transparent url(../images/bfm-icon_close.svg) no-repeat center center;
  content: "";
  display: block;
  height: 22px;
  width: 24px;
}

.search-toggle__button.close {
  opacity: 1;
  .bfm-icon.search {
    background: transparent url(../images/bfm-icon_close.svg) no-repeat center center;
  }
}


// .icon--close:before {content:     "\e600";}
.icon--envelope:before {content:  "\e601";}
.icon--external:before {content:  "\e602";}
.icon--facebook:before {content:  "\e603";}
.icon--linkedin:before {content:  "\e604";}
// .icon--menu:before {content:      "\e605";}
.icon--search:before {content:    "\e606";}
.icon--twitter:before {content:   "\e607";}
