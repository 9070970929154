.teaser {
  .block-title {
    border-top: 1px solid #ddd;
    color: #777;
    font-size: 14px;
    font-weight: $bold;
    padding: 15px 0 10px;
    text-align: center;
    text-transform: uppercase;
  }
}