/**
 * @file
 * Search
 */

/**
 * 1. Hide the search (tablet and down) within the site header context but
 *    not the javascript cloned mmenu copy
 */
 .search--small {
  .header & {
    @include make-xs-column(12);
    @include make-md-column(3);
    @include make-lg-column(3);
    display: none; /* [1] */
    @include respond-to(desktop-and-up) {
      display: block;
    }
  }
  .btn {
    border-radius: 0;
  }
}

.search-toggle__button {
  cursor: pointer;
  font-size: 30px;
}

.search-toggle {
  float: left;
  position: relative;
  z-index: 999;

  @include respond-to(mobile-and-down) {
    margin-left: 15px;
  }
}

.header__search {
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 400;

  .page-search & {
    display: block;
  }

  > form {
    margin: 0 auto;
    max-width: 1440px;
    text-align: center;

    input[type=text] {
      background: transparent;
      border: 0;
      color: $text-color;
      font-size: 22px;
      height: auto;
      padding: 44px 20px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;   
      text-align: center; 

      &:focus {
        outline: none; 
        // border: 1px solid #ed1c24;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        // -webkit-box-shadow: 0px 0px 5px  #ed1c24;
        // box-shadow: 0px 0px 5px  #ed1c24;
      }
    }

    .views-exposed-form {
      background: $white;
      margin: 0;
      padding: 0;
      position: relative;
    }

    .views-exposed-form .views-exposed-widget {
      float: none;
      margin: 0;
      padding-right: 0;
      padding-top: 0;
    }

    .views-submit-button {
      padding-right: 10px !important;
      position: absolute;
      right: 80px;
      top: 18px;

      @include respond-to(tablet-and-down){
        right: 20px;
      }

      button {
        background: transparent url(../images/bfm-icon_search.svg) no-repeat center center;
        display: block;
        height: 30px;
        margin: 7px 0;
        position: relative;
        text-indent: -99999em;
        width: 30px;
        z-index: 999;

        &:active {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}