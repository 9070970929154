/**
 * @file
 * BEM block for toggles.
 */
.toggle {
  background: transparent;
  clear: both;
  color: $gray-dark;
  float: right;
  font-size: 28px;
  margin-top: 10px;
  padding: 0 12px;
  position: relative;

  @include respond-to(mobile-and-down) {
  }
  
  .control--search & {
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  &:hover,
  &:focus {
    background-color: transparent;
    text-decoration: none;
  }
  &.active {
    background-color: transparent;
    .control--search & {
      background-color: transparent;
    }
  }
  
  &.active {
    .icon--close {
      display: inline-block;
    }
  }

  &:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.toggle--navigation {
  &.active {
    .icon--menu {
      display: none;
    }
  }
}

.icon--close {
  display: none;
}

.mm-right.mm-opening {
  .toggle .icon--menu {
    display: none;
  }
  .toggle .icon--close {
    display: block;
  }
}