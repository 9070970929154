/**
 * @file
 * Extend Drupal's field class
 */
 .field-group-fieldset {
  margin-top: $spacing-half;
  @include respond-to(tablet-and-up) {
    margin-top: $spacing-base;
  }
}

/* Responsive images within the main content areas */
.field-name-body,
.field-name-field-page-banner {
  img {
    max-width: 100%;
    height: auto;
  }
}

/* Add external link icon to links within the main content areas */
.field-name-body {
  .btn {
    text-decoration: none;
  }
  a[href^="http://"],
  a[href^="https://"],
  a[rel*='external'] {
    &:after {
      content: " \e602";
      font-size: em(14);
      @extend %icon;
    }
  }
  a[href*='<yourdomain>'], /* Add your site domain here */
  a[href*='sparksinteractive'] {
    &:after {
      content: "";
    }
  }
}
 
.field-name-field-file-attachment {
  .file {
    .meta {
      font-size: em(14);
      text-transform: uppercase;
      color: $gray-light;
    }
  }
}

