/**
 * @file
 * BEM block for menu.
 */
.menu__link {
  font-size: 14px;
}

body {
  .mm-menu {
    width: 260px !important;
  }
}