.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #dedcde;
  border: 0;
  border-radius: 0 !important;
  color: $text-color;
}

.pagination > li > a:hover {
  background-color: lighten(#dedcde, 2%);
}

.pagination > li > a, .pagination > li > span {
  border: 0;
}

.pagination > li:last-child > a {
  border-radius: 0 !important;
}

.pagination {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  li {
    display: inline-block;
    margin: 0 2px;
  }
}