/**
 * @file
 * BEM block for header.
 */
.header {
  background: transparent url(../images/bfm-bg-header.png) repeat-x center -9px;
  left: 0;
  right: 0;
  padding-top: $spacing-base;
  padding-bottom: $spacing-base;
  position: absolute;
  top: 0;
  z-index: 800;
  @include respond-to(tablet-large-and-down) {
    background-image: none;
    padding-bottom: 32px;
    padding-top: 32px;
  }

  .site__row {
    margin-left: 0;
    margin-right: 0;
  }


  .site__inner {
    @include respond-to(tablet-and-down){
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .bfm-icon.search {
  }
}

html.mm-top.mm-opening body.fixedHeader .header {
  transform: translateY(57px);
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease
}


.pre-ajax {
  //margin-bottom: 133px;
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease
}