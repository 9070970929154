/**
 * @file
 * OOCSS Helpers & BEM modifiers
 */

/* Useful within Display suite for removing margin from headings. */
.has-no-margin-top {
  margin-top: 0!important;
}
/**
 * https://github.com/twbs/bootstrap/pull/12679
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
*/
.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
/* Bootstrap gutter killer */
.no-gutter {
  &[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.hide-context-link .contextual-links-wrapper {
  display: none !important;
}
 /**
  * See Bootstrap. http://getbootstrap.com/css/#type-lists. Repeated here
  * because it is a common 'helper'.
  * Remove the default list-style and left margin on list items
  * (immediate children only). This only applies to immediate children list
  * items, meaning you will need to add the class for any nested lists as well.
  */
/*.list-unstyled {}*/

/**
 * Responsive margin top OOCSS spacing helpers.
 * Ideally always using margin above your site building blocks for consistency.
 */
.has-margin-top-base {
  margin-top: $spacing-half;
  @include respond-to(desktop-and-up) {
    margin-top: $spacing-base;
  }
}

.has-margin-top-and-half {
  margin-top: $spacing-base;
  @include respond-to(desktop-and-up) {
    margin-top: $spacing-and-half
  }
}

.has-margin-top-double {
  margin-top: $spacing-base;
  @include respond-to(desktop-and-up) {
    margin-top: $spacing-double;
  }
}

.has-margin-top-triple {
  margin-top: $spacing-base;
  @include respond-to(tablet-and-up) {
    margin-top: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    margin-top: $spacing-base * 3;
  }
}

.has-margin-top-half {
  margin-top: $spacing-half;
}

/* margin bottom */
.has-margin-bottom-base {
  margin-bottom: $spacing-half;
  @include respond-to(desktop-and-up) {
    margin-bottom: $spacing-base;
  }
}

.has-margin-bottom-and-half {
  margin-bottom: $spacing-base;
  @include respond-to(desktop-and-up) {
    margin-bottom: $spacing-and-half
  }
}

.has-margin-bottom-double {
  margin-bottom: $spacing-base;
  @include respond-to(desktop-and-up) {
    margin-bottom: $spacing-double;
  }
}

.has-margin-bottom-triple {
  margin-bottom: $spacing-base;
  @include respond-to(tablet-and-up) {
    margin-bottom: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    margin-bottom: $spacing-base * 3;
  }
}

.has-margin-bottom-half {
  margin-top: $spacing-half;
}

/* remove margin bottom */
.remove-margin-bottom-base {
  margin-bottom: -$spacing-half;
  @include respond-to(desktop-and-up) {
    margin-bottom: -$spacing-base;
  }
}

.remove-margin-bottom-and-half {
  margin-bottom: -$spacing-base;
  @include respond-to(desktop-and-up) {
    margin-bottom: -$spacing-and-half
  }
}

.remove-margin-bottom-double {
  margin-bottom: -$spacing-base;
  @include respond-to(desktop-and-up) {
    margin-bottom: -$spacing-double;
  }
}

.remove-margin-bottom-triple {
  margin-bottom: -$spacing-base;
  @include respond-to(tablet-and-up) {
    margin-bottom: -$spacing-double;
  }
  @include respond-to(desktop-and-up) {
    margin-bottom: -$spacing-base * 3;
  }
}

/* margin left and right */
.has-margin-base {
  @include respond-to(tablet-and-up) {
    margin-left: $content-gutter-width/6;
    margin-right: $content-gutter-width/6;
  }

  @include respond-to(tablet-large-and-up) {
    margin-left: $content-gutter-width/3;
    margin-right: $content-gutter-width/3;
  }

  @include respond-to(desktop-and-up) {
    margin-left: $content-gutter-width/2;
    margin-right: $content-gutter-width/2;
  }

  @include respond-to(wide-and-up) {
    margin-left: $content-gutter-width;
    margin-right: $content-gutter-width;
  }
}

.has-narrow-content {
  .block-title {
    @extend .group-narrow;
  }
}

.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * Responsive padding top OOCSS spacing helpers.
 * If a border is required above your site building block then use padding instead.
 */
.has-padding-top-base {
  padding-top: $spacing-half;
  @include respond-to(desktop-and-up) {
    padding-top: $spacing-base;
  }
}

.has-margin-top-and-half {
  margin-top: $spacing-base;
  @include respond-to(desktop-and-up) {
    margin-top: $spacing-and-half
  }
}

.has-padding-top-double {
  padding-top: $spacing-base;
  @include respond-to(desktop-and-up) {
    padding-top: $spacing-double;
  }
}

.has-padding-top-triple {
  padding-top: $spacing-base;
  @include respond-to(tablet-and-up) {
    padding-top: $spacing-double;
  }
  @include respond-to(desktop-and-up) {
    padding-top: $spacing-base * 3;
  }
}

.has-padding-space-base {
  padding-left: $content-gutter-width/3;
  padding-right: $content-gutter-width/3;

  @include respond-to(desktop-and-up) {
    padding-left: $content-gutter-width/2;
    padding-right: $content-gutter-width/2;
  }

  @include respond-to(wide-and-up) {
    padding-left: $content-gutter-width;
    padding-right: $content-gutter-width;
  }
}

.has-padding-top-half {
  padding-top: $spacing-half;
}

.has-border-top {
  border-top: 1px solid $brand-border;
}

/*  */

/* Use to offset elements 'right' within main body area. */
.has-offset-right {
  position: relative;
  @include respond-to(wide) {
    margin-right: -80px!important;
  }
  @include respond-to(wide-large) {
    margin-right: -120px!important;
  }
}

/* Use to offset elements 'left' within main body area. */
.has-offset-left {
  position: relative;
  @include respond-to(wide) {
    margin-left: -80px!important;
  }
  @include respond-to(wide-large) {
    margin-left: -120px!important;
  }
}

.has-centered-text {
  text-align: center;
}

.has-grey-bg {
  background: #e5e5e5;
  padding: $spacing-base $spacing-base*3;
  @include respond-to(tablet-large-and-down){
    padding: $spacing-base $spacing-base;
  }
  @include respond-to(mobile-and-down){
    padding: 20px;
  }
}

.has-white-bg {
  background: $white;
  padding: $spacing-base 20px;
  @include respond-to(tablet-large-and-down){
  }
  @include respond-to(mobile-and-down){
    padding: 20px;
  }
}

.small-block-title {
  .block-title {
    @extend .h3;
    color: $text-color;
  }
}

/* New style form elements (utilised for bCard) */

// Taller input with single bottom border
%has-tall-input-with-border-bottom {
  height: 40px;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #979797;
  border-radius: 0;
  background: #F5F5F5;
  box-shadow: none;
  font-size: 14px;
  color: #333;
  &:focus {
    background: $white;
  }
}

.has-tall-button-with-border-bottom, %has-tall-button-with-border-bottom {
  width: 100%;
  padding: 13px 0;
  //height: 50px;
  border-radius: 0;
  border-bottom: 1px solid $black;

  // Buttons get a disabled state briefly before submitting!?
  &.btn-default[disabled], &.btn-default[disabled]:hover {
    background-color: #d71219;
  }
}

// Stylised select dropdown - Custom 'arrow'.
.stylelised-select, %stylelised-select {
  .form-type-select {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 18px;
      right: 20px;
      transform: rotate(135deg);
      display: inline-block;
      width: 8px;
      height: 8px;
      border-style: solid;
      border-width: 2px 2px 0 0;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0;
      box-sizing: border-box;
      background-color: #F5F5F5;
      border: none;
      border-bottom: 1px solid #979797;
      border-radius: 0;
      &:focus {
        background: $white;
      }
    }
  }
} 

/* animations */
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
