/**
 * @file
 * Ignite includes exaggerated focus styles inspired by https://www.gov.uk/
 *
 * If your site doesn't require these accessibility features simply comment
 * out the only place where this partial is SASS @imported (scss/global.styles.scss).
 */
p {
  a {
    &:focus {
      outline-offset: 0;
    }
  }
}

a {
  &:focus {
    background-color: transparent;
    outline: 0;
  }
}

.breadcrumb__item {
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}

.btn-default {
  > a {
    &:focus {
      background-color: transparent;
      @include tab-focus;
    }
  }
}

.field-name-body a[href^="http://"]:after, .field-name-body a[href^="https://"]:after, .field-name-body a[rel*='external']:after {
  content: none !important;
}

.logo--secondary {
  .logo__link {
    &:focus {
      outline-offset: 0;
    }
  }
}

.nav {
  > li {
    > a {
      &:focus {
        background-color: $brand-focused;
      }
    }
  }
}

.navigation--secondary {
  .menu__link {
    &:focus {
      background-color: $brand-focused;
      outline-offset: 0;
    }
  }
}

.prose {
  @include respond-to(tablet-and-up) {
    a {
      &:focus {
        outline-offset: 0;
      }
    }
  }
}

.contextual-links-wrapper {
  a {
    &:focus {
      background-color: transparent;
      @include tab-focus;
    }
  }
}

a {
  &:focus {
    #admin-menu & {
      background-color: transparent;
      outline: none;
      outline-offset: 0;
    }
  }
}
