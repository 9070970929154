.bcard {
  // Site skeleton adjustments
  
  .site.header {
    top: 0 !important;
  }
}

%bcard--rounded-container {
  max-width: 409px !important;
  //margin-bottom: 160px !important;
  padding: 40px;
  border-radius: 8px;
  background-color: $secondary-bg;
}
// For instances when there is imagery filling the title region
&.bcard--inverted-title {
  .pre-content {
    .block-ignite-blocks {
      #tabs {
        display: none;
      }
    }
    h1.title {
      color: $white;
    }
    .region-pre-content {
      .block-crumbs {
        padding: 0;
        border-top: 0;
        .breadcrumb li a {
          color: $white;
        }
      }
    }
  }
  .site.content {
    @extend .has-margin-top-double;
  }
}

.bcard--signup {
  .pre-content {
    .site__inner {
      background: url('../images/bg-guitar.jpg') no-repeat center center;
      background-size: cover;
    }
    .region-pre-content {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      align-items: center;
      min-height: 293px;
      .block-crumbs {
        display: none;
        padding: 0;
        border-top: 0;
        .breadcrumb li a {
          color: $white;
        }
      }
      h1.title {
        margin: 0;
      }
      @include respond-to(tablet-and-down) {
        min-height: 150px;
      }
    }
  }
}

.bcard--login {
  .tooltip {
    // These are naff - nuke them at least for bCard stuff.
    display: none !important;
  }
  .pre-content {
    padding-top: 60px;
    .region-pre-content {
      min-height: auto;
    }
  }
  .site.content {
    margin-top: 50px;
  }
  .site.wrapper {
    background: url('../images/bg-guitar-large.jpg') no-repeat center top;
  }
  form {
    @extend %bcard--rounded-container;
    margin-bottom: 220px !important;
    .form-item {
      //margin-bottom: 0;
      label {
        margin-top: 0;
        text-transform: uppercase;
        color: #979797;
        font-size: 12px;
        font-weight: normal;
        vertical-align: top;
      }
      input {
        @extend %has-tall-input-with-border-bottom;
      }
      
      &.error {
        background: none;
        label {
           color: $error-text-color;
        }
      }
    }
    button.form-submit {
      @extend %has-tall-button-with-border-bottom;
    }
  }
}

.bcard--my-bcard {
  .pre-content {
    padding-top: 30px;
  }
  .site.content {
    margin-top: 10px;
  }
  .site.wrapper {
    max-width: 1280px;
    margin: 0 auto;
    background: #fff url('../images/bg-guitar-large.jpg') no-repeat center top;
  }
  .messages {
    &.warning, &.status {
/*      display: none; */
    }
  }
  .bcard__bcard-details {
    margin: 0 auto 65px;
    @extend %bcard--rounded-container;
    padding-top: 143px;
    text-align: center;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center top;
    @include respond-to(mobile) {
      margin-bottom: 85px;
    }
  }
  .bcard__number {
    display: block;
    margin: -16px 0 0;
    
    font-weight: bold;
    .field-name-field-bcard-number {
      display: inline-block;
      padding: 7px 14px;
      line-height: 1;
      color: $white;
      background: #333;
    }
  }
  .bcard__name {
    font-size: 18px;
  }
  .bcard__barcode {

  }
}

.bcard--checkout {
   .block-main-title {
      border-top: 1px solid #ccc;
      margin: 0px 80px 0px;
      padding: 20px 0px 0 0;
    }
  .bcard-signup__subs-type, .bcard-signup--subs-type {
    font-size: 24px;
    color: $secondary-text;
    text-align: center;
  }
  #commerce-checkout-form-checkout {
    @extend .group-narrow;
    @extend %stylelised-select;
    label, .fieldset-legend, .password-strength, .password-confirm {
      margin-top: 0;
      text-transform: uppercase;
      color: #979797;
      font-size: 12px;
      font-weight: normal;
      vertical-align: top;
    }
    .form-item.error { 
      label {
        color: $error-text-color;
      }
    }
    fieldset {
      background: none;
      border: none;
      box-shadow: none;
      .panel-body {
        padding: 0;
      }
    }
    .password-strength, .password-confirm {
      vertical-align: top;
    }
    .password-confirm {
      width: auto;
    }
    .password-suggestions {
      display: none !important;
    }
    .form-wrapper.form-group {
      margin-bottom: 25px;
    }
    .form-item {
      margin-bottom: 0;
      &.error {
        background: none;
        label {
           color: $error-text-color;
        }
      }
    }
    input[type="text"], input[type="password"], select {
      width: 100%;
      @extend %has-tall-input-with-border-bottom;
    }
    .checkout-continue {
      @extend %has-tall-button-with-border-bottom;
    }
    
    .form-type-checkbox {
      display: flex;
      align-items: center;
      width: calc(50% - 10px);
      margin: 0;
      margin-bottom: 10px;
      padding: 8px 15px;
      background: #F5F5F5;
      border-bottom: 1px solid #979797;
      &:nth-child(odd):not(.form-item-commerce-user-profile-pane-field-subscribe-to-newsletter-und-0-subscribe) {
        margin-right: 20px;
      }
      &:last-child {
        //margin-bottom: 0px;
      }
      input {
        width: 18px;
        height: 18px;
        margin: 0;
        border-radius: 0;
      }
      label {
        width: 100%;
        padding-left: 25px;
        text-transform: none;
        color: #333;
        font-size: 14px;
      }
      @include respond-to(tablet-and-down) {
        display: block;
        width: 100%;
        line-height: normal;
      }
    }
    .form-checkboxes, .field-name-field-subscribe-to-newsletter .panel-body {
      display: flex;
      flex-flow: row wrap;
      @include respond-to(tablet-and-down) {
        display: block;
      }
    }
    div {
      line-height: 23px;
    }
    .password-indicator {
      display: none;
    }
  }
  .form-item-account-pass {
    display: flex;
    flex-flow: row wrap;
    vertical-align: top;
    margin-bottom: 8px;
    div.form-type-password {
      width: calc(50% - 10px);
      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
    @include respond-to(tablet-and-down) {
      display: block;
      width: 100%;
      div.form-type-password {
        width: 100%;
        &:first-of-type {
          margin-bottom: 25px !important;
        }
      }
    }
  }
  .form-columns-wrapper {
    .form-wrapper {
      display: inline-flex;
      flex-flow: row wrap;
      width: calc(50% - 10px);
      vertical-align: top;
      @include respond-to(tablet-and-down) {
        display: block;
        width: 100%;
      }
      div {
        width: 100%;
      }
      &.form-group:nth-child(odd):not(.field-name-field-terms-conditions) {
        margin-right:20px;
      }
    }
    .panel {
      width: 100%;
      margin: 0px;
      background: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      div {
        width: 100%;
        padding: 0;
      }
      legend {
        padding: 0;
        background: none;
        border: none;
        line-height: 23px;
        .fieldset-legend {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          line-height: $line-height-base;
        }
      }
    }
    .form-item-commerce-user-profile-pane-field-date-of-birth-und-0-value {
      &.form-item {
        margin-bottom: 0;
      }
      label {
        display: none;
      }
    }
    .field-name-field-know-more.form-wrapper {
      display: block;
      width: 100%;
      margin-right: 0;
      .form-item-commerce-user-profile-pane-field-know-more-und-other {
        width: calc(50% - 10px);
      }
    }
    .field-name-field-subscribe-to-newsletter {
      margin-right: 20px;
      fieldset {
        margin-right: 0 !important;
      }
    }
    .field-name-field-terms-conditions {
      vertical-align: bottom;
    }
    .form-item-commerce-user-profile-pane-field-subscribe-to-newsletter-und-0-subscribe,
    .form-item-commerce-user-profile-pane-field-terms-conditions-und {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}

.page-user-edit {
  #user-profile-form {
    .form-columns-wrapper {
      .form-wrapper {
        width: 100%;
        vertical-align: top;
        @include respond-to(tablet-and-down) {
          display: block;
          width: 100%;
        }
        div {
          //width: 100%;
        }
        .form-type-password, .form-item-mail {
          margin-bottom: 25px;
        }
        .form-item-pass {
          display: flex;
          flex-flow: row wrap;
          vertical-align: top;
          margin-bottom: 8px;
          div.form-type-password {
            width: calc(50% - 10px);
            &:nth-child(odd) {
              margin-right: 20px;
            }
          }
          @include respond-to(tablet-and-down) {
            display: block;
            width: 100%;
            div.form-type-password {
              width: 100%;
              &:first-of-type {
                margin-bottom: 25px !important;
              }
            }
          }
        }
        #account-pass-restrictions {
          display: none;
        }
      }
      .panel {
        width: 100%;
        margin: 0px;
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        div {
          width: 100%;
        }
        legend {
          padding: 0;
          background: none;
          border: none;
          line-height: 23px;
          .fieldset-legend {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            line-height: $line-height-base;
          }
        }
      }
      
      .form-item-field-date-of-birth-und-0-value {
        &.form-item {
          margin-bottom: 0;
        }
        label {
          display: none;
        }
      }
      .field-name-field-know-more.form-wrapper {
        display: block;
        width: 100%;
        margin-right: 0;
        .form-item-commerce-user-profile-pane-field-know-more-und-other {
          width: calc(50% - 10px);
        }
      }
      .field-name-field-subscribe-to-newsletter {
        margin-right: 20px;
        fieldset {
          margin-right: 0 !important;
        }
      }
      .field-name-field-terms-conditions {
        vertical-align: bottom;
      }
      .form-item-commerce-user-profile-pane-field-subscribe-to-newsletter-und-0-subscribe,
      .form-item-commerce-user-profile-pane-field-terms-conditions-und {
        width: 100% !important;
        margin-right: 0 !important;
      }
      .date-padding {
        padding: 0;
      }
    }
  }
}




// Just share the easy bits
.bcard--checkout, .page-user-edit {
  #commerce-checkout-form-checkout, #user-profile-form {
    @extend .group-narrow;
    @extend %stylelised-select;
    label, .fieldset-legend, .password-strength, .password-confirm {
      margin-top: 0;
      text-transform: uppercase;
      color: #979797;
      font-size: 12px;
      font-weight: normal;
      vertical-align: top;
    }
    .form-item.error { 
      label {
        color: $error-text-color;
      }
    }
    fieldset {
      background: none;
      border: none;
      box-shadow: none;
      .panel-body {
        padding: 0;
      }
    }
    .password-strength, .password-confirm {
      vertical-align: top;
    }
    .password-confirm {
      width: auto;
    }
    .password-suggestions {
      display: none !important;
    }
    .form-wrapper.form-group {
      margin-bottom: 25px;
    }
    .form-item {
      margin-bottom: 0;
      &.error {
        background: none;
        label {
           color: $error-text-color;
        }
      }
    }
    input[type="text"], input[type="password"], select {
      width: 100%;
      @extend %has-tall-input-with-border-bottom;
    }
    .checkout-continue {
      @extend %has-tall-button-with-border-bottom;
    }

    .form-type-checkbox {
      display: flex;
      align-items: center;
      width: calc(50% - 10px);
      margin: 0;
      margin-bottom: 10px;
      padding: 8px 15px;
      background: #F5F5F5;
      border-bottom: 1px solid #979797;
      &:nth-child(odd):not(.form-item-commerce-user-profile-pane-field-subscribe-to-newsletter-und-0-subscribe) {
        margin-right: 20px;
      }
      &:last-child {
        //margin-bottom: 0px;
      }
      input {
        width: 18px;
        height: 18px;
        margin: 0;
        border-radius: 0;
      }
      label {
        width: 100%;
        padding-left: 25px;
        text-transform: none;
        color: #333;
        font-size: 14px;
      }
      @include respond-to(tablet-and-down) {
        display: block;
        width: 100%;
        line-height: normal;
      }
    }
    .form-checkboxes, .field-name-field-subscribe-to-newsletter .panel-body {
      display: flex;
      flex-flow: row wrap;
      @include respond-to(tablet-and-down) {
        display: block;
      }
    }
    div {
      line-height: 23px;
    }
    .password-indicator {
      display: none;
    }
    .input-group {
      width: 100%;
      .input-group-addon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 10;
        background: none;
        border: none;
      }
    }
  }
}


.bcard-subs-chooser {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @extend .has-margin-top-double;
  h2 {
    margin-bottom: 30px;
    font-size: 24px;
    text-align: center;
  }
  
  // Implement stylised select dropdown
  @extend %stylelised-select;
  select {
    @extend %has-tall-input-with-border-bottom;
  }
  button, .btn {
    @extend %has-tall-button-with-border-bottom;
  }

  .form-submit {
    width: 100%;
  }
}

.bcard-pricing {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  @extend .has-margin-top-base;
  .bcard-pricing__item {
    flex: 0 0 33%;
    min-width: 310px;
    text-align: center;
    h2 { 
      margin-bottom: 0;
    }
  }
  .bcard-pricing__price {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    color: $link-color;
  }
  .bcard-pricing__perks {
    padding: 0 50px;
    font-size: 14px;
  }
  .bcard-pricing__perk {
    margin: 10px 0;
    &:before {
      content: '';
      display: block;
      width: 50%;
      height: 9px;
      margin: 0 auto;
      border-top: 1px dashed #979797;
    }
  }
  @include respond-to(tablet-and-down) {
    .bcard-pricing__perks {
      padding: 0 30px;
    }
  }
}

.bcard-promos {
  margin-top: 100px;
  .block-title {
    text-align: center;
    font-size: 22px;
    font-weight: normal;
    color: $secondary-text;
  }
  .bcard-promo__items {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    .bcard-promo__item {
      text-align: center;
      .bcard-promo__content {
        .bcard-promo__logo {
          width: auto;
          height: 100px;
          margin: 15px;
        }
        .bcard-promo__code {
          color: $link-color;
          font-weight: bold;
        }
      }
    }
  }
}
