html.mm-opened .mm-page {
}


html.mm-top.mm-opening .mm-slideout,
html.mm-top.mm-opening .pre-ajax {
  -webkit-transform: translate(0, 57px) !important;
  -moz-transform: translate(0, 57px) !important;
  -ms-transform: translate(0, 57px) !important;
  -o-transform: translate(0, 57px) !important;
  transform: translate(0, 57px) !important;
}

html.mm-right.mm-opening .mm-slideout,
html.mm-right.mm-opening .pre-ajax {
  -webkit-transform: translate(-220px, 0);
  -moz-transform: translate(-220px, 0);
  -ms-transform: translate(-220px, 0);
  -o-transform: translate(-220px, 0);
  transform: translate(-220px, 0);

 
}

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(30%);
  -moz-transform: translateX(30%);
  -ms-transform: translateX(30%);
  -o-transform: translateX(30%);
  transform: translateX(30%);
}

html.mm-top.mm-opening body.fixedHeader .mm-slideout {
  transform: none !important;
}

nav#mmenu_right {
  background: #353535 url(../images/bfm-bg-mmenu.gif) no-repeat left top;
  background-size: cover;
  z-index: 999;
}

.mm-menu {
  width: 220px !important;
}

.mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen) {
  background: transparent;
}

.menu.mm-list > li:hover {
  background-color: rgba(0,0,0,0.3);
}

.mm-slideout, .pre-ajax, html.mm-slide .mm-menu {
  // -webkit-transition: -webkit-transform 0.2s ease !important;
  // -ms-transition: -ms-transform 0.2s ease !important;
  // transition: transform 0.2s ease !important;
}

.mm-list > li:first-child > a {
  border-top: 1px solid rgba(255,255,255,0.1);
}
.mm-list a.mm-subclose {
  margin-top: 0;
}
.mm-list a.mm-subclose:before {
  left: 6px;
}
.mm-list a.mm-subclose:before {
  margin-bottom: -4px !important;
}
.mm-list > li > a {
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: $white;
  font-weight: $bold;
  margin-left: 17px;
  padding: 7px 0 !important;

  @include respond-to(tablet-and-down){
    padding: 12px 0 !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
  }
}

.toggle--navigation:active {
  background: transparent !important;
}