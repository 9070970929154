/**
 * @file
 * BEM block for Slats.
 *
 * A slat is like a Drupal teaser (view mode) but the ambiguous name
 * means it is more versatile and can be used outside of the view mode context.
 *
 * The 'slat' idea was derived from http://pea.rs/content/slats-html5
 *
 * Here we establish some base level BEM-style slat behaviour
 * Use these classes to theme elements like teasers eg: via Display Suite.
 * or apply to Drupal views.
 */
$slat-border: $brand-border;
$slat-background-color: $gray-lighter;
$slat-text-color: $text-color;

.slat__media {
  > a {
    display: block;
  }
}

.slat__title {
  a {
    color: $slat-text-color;
    &:hover,
    &:focus {
      color: $slat-text-color;
      text-decoration: underline;
      @include single-transition(all, 0.05s, ease-in);
    }
    &:visited {
      color: $brand-visited;
    }
  }
  @include respond-to(mobile) {
    font-size: $font-size-h4;
  }
  .site__sidebar & {
    font-size: $font-size-h4;
  }
}

.slat__read-more {
  margin-top: $spacing-half;
  > a {
    color: $gray-dark;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.slat__date {
  @extend .date;
}

.slat__term {
  font-size: em(14);
  > a {
    &:focus {
      outline-offset: 0;
    }
  }
}

.slat__link {
  position: relative;
  float: left;
  width: 100%;
  display: block;
  .slat__title {
    color: $slat-text-color;
    @include single-transition(none);
  }
  .slat__body {
    color: $slat-text-color;
  }
  .slat__date {
    color: lighten($gray-light,10%);
  }
  &:hover,
  &:focus {
    text-decoration: none;
    .slat__title {
      color: $slat-text-color;
      text-decoration: underline;
      @include single-transition(all, 0.05s, ease-in);
    }
    .slat__body {
      color: $slat-text-color;
    }
    .slat__date {
      color: lighten($gray-light,10%);
    }
  }
  &:focus {
    background-color: transparent;
  }
  &:visited {
    .slat__title {
      color: $brand-visited;
    }
  }
}

.slat--has-top-border {
  @extend .has-border-top;
}

.slat--has-top-margin {
  margin-top: $spacing-base;
  @include respond-to(tablet-and-up) {
    margin-top: $spacing-and-half;
  }
}

.slat--has-background-colour {
  background-color: $slat-background-color;
  .slat__content {
    padding: 0 $spacing-base $spacing-base;
  }
}

.slat--sometimes-has-left-aligned-media,
.slat--sometimes-has-right-aligned-media {
  .slat__media {
    margin-bottom: $spacing-base;
  }
  .slat__content {
    padding: 0 0 $spacing-half 0;
  }
  &.slat--has-background-colour {
    .slat__content {
      padding: 0 $spacing-half $spacing-half;
      @include respond-to(tablet-and-up) {
        padding: 0 $spacing-three-quarters $spacing-half 0;
      }
    }
  }
}

.slat--sometimes-has-left-aligned-media {
  .slat__media {
    @include respond-to(tablet) {
      @include span(3 of 6 before first);
      margin-right: $spacing-and-half;
    }
    @include respond-to(desktop-and-up) {
      @include span(5 of 12 before first);
      margin-right: $spacing-and-half;
    }
  }
}

.slat--sometimes-has-right-aligned-media {
  .slat__media {
    @include respond-to(tablet) {
      @include span(3 of 6 before first);
      margin-left: $spacing-and-half;
    }
    @include respond-to(desktop-and-up) {
      @include span(5 of 12 before first);
      margin-left: $spacing-and-half;
    }
  }
}

.slat--always-has-left-aligned-media,
.slat--always-has-right-aligned-media {
  &.slat--has-background-colour {
    @include clearfix;
    .slat__content {
      padding: 0 $spacing-half $spacing-half;
      @include respond-to(tablet-and-up) {
        padding: 0 $spacing-three-quarters $spacing-half 0;
      }
    }
  }
}

.slat--always-has-left-aligned-media {
  .slat__media {
    @include respond-to(tablet) {
      @include span(3 of 6 before first);
    }
    @include respond-to(desktop-and-up) {
      @include span(5 of 12 before first);
    }
  }
  .slat__content {
    @include respond-to(tablet) {
      @include span(3 of 6 before last);
    }
    @include respond-to(desktop-and-up) {
      @include span(7 of 12 before last);
    }
  }
}

.slat--always-has-right-aligned-media {
  .slat__media {
    @include respond-to(tablet) {
      @include span(3 of 6 before last);
    }
    @include respond-to(desktop-and-up) {
      @include span(5 of 12 before last);
    }
  }
  .slat__content {
    @include respond-to(tablet) {
      @include span(3 of 6 before first);
    }
    @include respond-to(desktop-and-up) {
      @include span(7 of 12 before first);
    }
  }
  &.slat--has-background-colour {
    .slat__content {
      padding: 0 0 $spacing-half $spacing-base;
    }
  }
}
