/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
      -ms-transform: translatez(0);
      -o-transform: translatez(0);
      transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  color: $link-color;
  position: absolute;
  bottom: -44px;// -30px;
  width: 100%;
}

/* LOADER */

.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(images/bx_loader.gif) center center no-repeat transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
  text-align: center;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: $link-color;
  padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;   
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  background: transparent url(../images/bfm-icon_prev.svg) no-repeat center center;
}


.bx-wrapper .bx-prev {
  left: 20px;
  // background: url(images/controls.png) no-repeat 0 -32px;
  @include respond-to(mobile-and-down){
    left: 0;
  }
}

.bx-wrapper .bx-next {
  background: transparent url(../images/bfm-icon_next.svg) no-repeat center center;
  right: 20px;
  // background: url(images/controls.png) no-repeat -43px -32px;
  @include respond-to(mobile-and-down){
    right: 0;
  }
}

.bx-wrapper .bx-prev:hover {
  // background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  // background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  background-size: 75%;
  display: inline-block;
  position: absolute;
  top: 0;
  outline: 0;
  height: 18px;
  text-indent: -9999em;
  width: 34px;
  z-index: 9999;

  @include respond-to(mobile-and-down){
    background-size: 75%;
    opacity: 0.6;

    &:active {
      opacity: 1;
    }
  }
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -11px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -44px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666\9;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}
