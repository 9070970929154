/**
 * @file
 * Forms
 *
 * Drupal forms are time consuming to inject with nice BEM like classes
 * so we just style them the old fashioned way...
 */
.input-group {
  .form-control {
    &:first-child {
      @include border-radius(0);
    }
  }
}

.views-exposed-form {
  background-color: $gray-lighter;
  padding: $spacing-half $spacing-base $spacing-base;
  margin: $spacing-half 0;
  @include respond-to(tablet-and-up) {
    margin: $spacing-base 0;
  }
}

.webform-client-form, .node-webform  .field-item {
  @extend .group-narrow;
}

.form-item, .node-webform .field-name-body {
  margin-bottom: 30px;
}

.node-webform .field-name-body {
}

.form-control,
input[type=email] {
  display: block;
  width: 100%;
  height: 37px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.4375;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

textarea.form-control {
  @include respond-to(mobile-and-down) {
    max-height: 200px;
  }
}

.help-block {
  margin-top: 5px;
} 

.captcha {
  margin-bottom: 30px;
}

.mailchimp-newsletter-mergefields {
  margin-top: 30px;
}

.page-user {
  form {
    margin: 0 auto;
    max-width: 615px;
  }
}

.not-logged-in.page-user {
  .block-ignite-blocks {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    // max-width: 615px;
  }
  .block-system {
    margin: 0 auto;
    // max-width: 615px;
  }
  #user-login,
  .user-info-from-cookie {
    
    >div {
    }
    input {
    }
    .panel {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      .panel-body, .panel-heading {
        background-color: transparent;
        padding: 0;
      }
      .panel-heading {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .panel-title {
        font-weight: bold;
      }
    }
    fieldset.date-combo .container-inline-date .date-padding {
      padding: 0;
    }
  }
}

.page-node-add {
  form {
    margin: $spacing-base auto;
    max-width: 615px;
  }
}

#user-register-form {
  .date-combo {
    .panel-heading {
      border: 0;
      padding-bottom: 0;
    }
    .panel-body label {
      display: none;
    }
  }
  .form-type-checkbox {
    label {
      padding-left: 0;
    }
  }
  .form-checkboxes {
    margin-top: $spacing-half;
    .form-type-checkbox {
      display: inline-block;
      margin-right: $spacing-half;
      margin-top: 0;
      vertical-align: top;
    }
  }
  .form-submit {
    margin-bottom: $spacing-base;
  }
}