.node-type-news {
  h1 {
    margin-bottom: 40px;
  }
}

.node-news {
  height: 100%;
  position: static;

  .field-name-body {
    margin-bottom: 30px;
  }

  .field-name-field-image {
    margin-bottom: 70px;
    text-align: center;

    @include respond-to(tablet-large-and-down){
      margin-top: 30px;

      .gallery-images__inner.js-slider-applied {
        margin-bottom: 100px;
      }
    }

    @include respond-to(mobile-and-down){
      margin-top: 0;
    }

    .bx-pager {
      display: none;
    }

    .bx-next, .bx-prev {
      bottom: -40px;
      top: inherit;
    }
  }

  .gallery-images__item {
    text-align: center;
    img {
      display: inline-block;
      height: auto;
      max-width: 100%;
    }
  }

  .field-name-field-video-links {
    clear: both;

    iframe {
      height: 730px;
      width: 100%;

      @include respond-to(tablet-large-and-down){
        height: 300px;
      }
      @include respond-to(mobile-and-down){
        height: 173px;
      }
    }

    .bx-controls {
      position: absolute;
      left: 0;
      right: 0;
      top: 730px;

      @include respond-to(tablet-large-and-down){
        top: 300px;
      }
      @include respond-to(mobile-and-down){
        top: 173px;
      }
      
    }

    .bx-controls-direction {
      margin-top: 10px;
    }

    .bx-default-pager {
      color: $link-color;
      font-size: 16px;
    }
    
    .bx-wrapper {
      margin-bottom: 0;
    }

    .bx-wrapper .bx-controls-direction a {
      color: $link-color;
      position: relative;
      top: 48px;
      z-index: 200;
    }

    .bx-wrapper .bx-pager {
      padding-top: 0;
      top: 25px;
    }

    .bx-next {
      float: right;
    }

    .oembed--title {
      font-size: 25px;
      font-weight: $bold;
      margin-top: 10px;
      padding: 0 70px;
      text-align: center;
      @include respond-to(mobile-and-down){
        font-size: 16px;
        padding: 0 45px;
      }
    }

    div.video-wrapper.js-slider-applied .oembed--title {
      margin-top: 43px;
    }

  }
  
  .field-name-post-date {
    color: $secondary-text;
    font-size: 24px;
  }

  .video-links .bx-pager {
    display: none;
  }

}

.view-news {
  .is-using-teaser-image img {
    height: auto;
  }

  .is-using-teaser-image {
  }
}