.banner--current-show {
  background: $primary-bg;
  background-position: center center;
  background-size: cover;
  min-height: 542px;
  position: relative;

  @include respond-to(desktop-and-down) {
    min-height: 470px;
  }

  @include respond-to(mobile-and-down) {
    min-height: 250px;
  }

  .views-field-field-page-banner img {
    display: none;
  }

  .show--up-next {
    background: rgba(255,255,255,0.7);
    bottom: 0;
    height: 82px;
    font-weight: $bold;
    padding: 30px 33px;
    position: absolute;
    left: 100%;
    width: 542px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    transform-origin: left bottom;

    > div {
      position: relative;
      // top: 50%;
      // -webkit-transform: translateY(-50%);
      // -moz-transform: translateY(-50%);
      // transform: translateY(-50%);
    }

    @include respond-to(desktop-and-down){
      display: none;
    }

    .views-field {
      display: inline;

      div {
        display: inline;
      }
    }
  }

  .show--on-now {
    img {
      width: 100%;
    }
  }

  .show--on-now .views-field-nothing {
    bottom: 0;
    font-family: $font-family-secondary;
    color: $white;
    padding: 80px;
    position: absolute;

    @include respond-to(desktop-and-down){
      padding: 20px;
    }

    .on-now {
      font-size: 18px;
      font-weight: $bold;
      text-transform: uppercase;

      span {

        &.on-now {
        }

        &.on-now:after {
          background: transparent url(../images/bfm-icon_playing02.svg) no-repeat center center;
          content: "";
          display: inline-block;
          height: 23px;
          margin-left: 5px;
          margin-top: -14px;
          vertical-align: middle;
          width: 23px;
        }
      }

      .time-default  {
        display: inline;
      }
    }

    .show-name,
    .show-with {
      color: $white;
      display: inline;
      font-size: 36px;
      font-weight: $bold;
      @include respond-to(mobile-and-down) {
        font-size: 25px;
      }

      a:active, a:focus, a:visited, a:link {
        color: $white;
        text-decoration: none;
      }

      h3 {
        color: $white;
        font: $bold 60px/56px $font-family-secondary;
        margin: 0 0 5px;

        @include respond-to(mobile-and-down) {
          font: $bold 42px/32px $font-family-secondary;
        }
      }
    }
  }
}