/**
 * @file
 * List group item
 * Override Bootstrap list group item styles
 */
.list-group-item {
  padding: $spacing-half;
  &:first-child {
    @include border-radius(0);
  }
  @include respond-to(tablet-and-up) {
    padding: $spacing-base;
  }

  &.invert {
    background: transparent;
    border: 0;
    padding: 0;
  }

  .view-content {
    @include clearfix;
  }

}

.item-list {
  @include clearfix;
}

.item-list .list-unstyled {
  display: flex;
  flex-flow: row wrap;

  > li {
    background: $white;
    //min-height: 426px;
    position: relative;

    a {
      overflow: hidden;
    }
    
    @include respond-to(mobile-and-down){
      margin-bottom: 20px !important;
    }

    &:hover {
      .field-name-title h3 {
        text-decoration: underline;
        // text-decoration-color: rgba(255, 255, 255, 0.9);
      }
    }

    a:not(.contextual-links-trigger) {
      //background-color: $primary-bg;

      .field-name-field-page-banner {
        opacity: 1;
        transition: opacity 0.4s ease;
      }

      &:hover .field-name-field-page-banner {
        opacity: 0.8;
        transition: opacity 0.4s ease;
      }

      img {
        height: auto;
        max-width: 100%;
      }

      &:hover  img {
      }

      .field-name-field-image {
        background-color: $primary-bg;
      }
    }
  }


  .field-name-title {
    // min-height: 143px;
    @extend .border-box;

    @include respond-to(tablet-large-and-down){
    }

    h3 {
      color: $primary-text;
      font-size: 24px;
      font-weight: $bold;
      margin: 0;
      @include respond-to(desktop-and-down) {
        font-size: 19px;
      }
      @include respond-to(tablet-large-and-down){
        font-size: 17px;

      }
    }
  }

  .group-link {
    display: block;
    height: 100%;
    @include clearfix;
  }

  .field-name-title {
    background: $white;
    bottom: 0;
    left: 0;
    height: 33.33333%;
    padding: 30px 20px;
    // position: absolute;
    right: 0;
    width: 100%;
    @include respond-to(tablet-large-and-down) {
      padding: 20px;
    }
    @include respond-to(mobile-and-down) {
      height: auto;
    }
  }

  @include respond-to(mobile-and-down){
    div:not(.is-using-teaser-image) .field-name-title {
      min-height: 0;
      position: static;
    }
  }

  .is-using-teaser-image {
    .field-name-title {
      background: transparent;
      position: absolute;
      top: inherit;

      @include respond-to(mobile-and-down){
        min-height: 0;
      }

      h3 {
        color: $white;
      }

    }
  }

  .node-news {
  }
  
}