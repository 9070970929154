/**
 * @file
 * Accessibility skip link
 */
 .skip-link {
  text-align: center;
  > a {
    display: inline-block;
    outline-offset: 0;
    padding: $spacing-quarter;
  }
}