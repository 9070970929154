.maintenance-page.in-maintenance {
	display: grid;
	height: 100vh;
	align-content: flex-start;
	background-color: white;
	text-align: center;
	#page {
    max-width: 660px;
    margin: 0 auto;
		margin-top: 10vw;
		padding: 0 20px;
	}
	#footer-wrapper, #name-and-slogan {
		display: none;
	}
	
}