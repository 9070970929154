.mailchimp-signup-subscribe-form {
  @extend .group-narrow;
}

.webform-client-form-4415 {
  .webform-component--first-name,
  .webform-component--last-name {
    @extend .border-box;
    @include respond-to(tablet-and-up) {
      float: left;
      width: 50%;
    }
  }
  @include respond-to(tablet-and-up) {
    .webform-component--first-name {
      padding-right: 22px;
    }

    .webform-component--last-name {
      padding-left: 22px;
    }
  }
}

#node-4415 {
  .field-name-body {
    text-align: center; 
  }
}