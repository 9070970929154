/**
 * @file
 * BEM block for element.
 *
 */
.element {
  margin: 0 0 $spacing-base 0;
  @include respond-to(tablet-and-up) {
    margin: 0 0 $spacing-prose-base 0;
  }
  &.has-caption {
    > p {
      color: lighten($gray-dark, 15%);
      padding: $spacing-base 0 $spacing-quarter;
      font-size: 85%;
      border-bottom: 1px solid $brand-border;
      @include respond-to(tablet-and-up) {
        padding: $spacing-prose-base 0 $spacing-prose-quarter;
      }
    }
  }
  &.has-featured-caption {
    @include respond-to(wide-and-up) {
      position: relative;
    }
    > div {
      @include respond-to(wide-and-up) {
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 275px;
        background-color: $white;
      }
    }
    p {
      color: lighten($gray-dark, 15%);
      padding: $spacing-base 0 $spacing-quarter;
      font-size: 85%;
      border-bottom: 1px solid $brand-border;
      @include respond-to(wide-and-up) {
        padding: $spacing-base $spacing-base $spacing-quarter $spacing-base;
        border-bottom: none;
      }
    }
  }
  &.pull-left,
  &.pull-right {
    width: 100%;
    @include respond-to(tablet-and-up) {
      width: 50%;
    }
    @include respond-to(wide-and-up) {
      width: auto;
    }
  }
  &.pull-left {
    margin: em(4) 0 $spacing-base 0;
    @include respond-to(tablet-and-up) {
      margin: em(8) $spacing-prose-double $spacing-prose-base 0;
    }
  }
  &.pull-right {
    margin: em(4) 0 $spacing-base 0;
    @include respond-to(tablet-and-up) {
      margin: em(8) 0 $spacing-prose-base $spacing-prose-double;
    }
  }
}

.element--featured {
  margin: $spacing-half 0 $spacing-base;
  @include respond-to(tablet-and-up) {
    margin: $spacing-prose-base 0 $spacing-prose-base;
  }
}
