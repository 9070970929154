.site__sidebar {

  .block-title {
    color: $secondary-text;
    font-size: 22px;
    font-weight: $bold;
    margin-bottom: 20px;
    margin-top: 0;
  }

  a {
    color: $primary-text;

    &:hover {
      color: $link-color;
    }
  }

  @include respond-to(mobile-and-down){
    margin-left: 20px;
    margin-right: 20px;
  }
}

.has-sidebar > div:not(.contextual-links-wrapper), .site__sidebar-inner {
  border-top: 1px solid #ccc;
  margin-top: 40px;
  padding-top: 40px !important;

  .page-bcasts & {
    border-top: 0;
  }

  @include respond-to(tablet-large-and-down){
    margin-top: 15px;
    padding-top: 20px !important;
  }
}
