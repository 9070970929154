.block-contact-form,
.mailchimp-signup-subscribe-form {

  h2.block-title {
    color: $text-color;
  }

  form {
    margin-top: 30px;
  }

  .form-item {
    margin-bottom: 30px;
  }
  
  .webform-component--name,
  .form-item-mergevars-FNAME {
    padding-right: 22px;
    @include respond-to(mobile-and-down){
      padding-right: 0;
    }
  }

  .webform-component--email,
  .form-item-mergevars-LNAME {
    padding-left: 22px;
    @include respond-to(mobile-and-down){
      padding-left: 0;
    }
  }

  .webform-component--name,
  .webform-component--email,
  .form-item-mergevars-FNAME,
  .form-item-mergevars-LNAME {
    float: left;
    width: 50%;
    @extend .border-box;

    @include respond-to(mobile-and-down){
      float: none;
      width: 100%;
    }

  }
}

.confact-info {

  h2, h3 {
    color: $text-color;
    margin-top: 0;
  }

  > ul,
  > ul li > ul {
    list-style: none;
    padding-left: 0;
  }

  > ul {
    -webkit-column-count: 4; /* Chrome, Safari, Opera */
    -moz-column-count: 4; /* Firefox */
    column-count: 4;
    padding-top: 40px;

    @include respond-to(mobile-and-down){
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
      -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
      -moz-column-gap: 20px; /* Firefox */
      column-gap: 20px;
    }

    > li {
      -webkit-column-break-inside: avoid;
      break-inside: avoid-column;
      padding-bottom: 30px;

      li {
        margin-bottom: 5px;
      }
    }
  }
}

dd.columns, ul.columns {
  border-bottom: 1px solid #d5d5d5;
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
  -moz-column-gap: 20px; /* Firefox */
  column-gap: 20px;
  list-style: none;
  padding-left: 0;
  padding-top: 30px;
  @extend .has-border-top;

  dl, li {
    -webkit-column-break-inside: avoid;
    break-inside: avoid-column;
    margin: 0;
    padding-bottom: 30px;
  }
}
